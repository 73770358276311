import axios from "axios";

const configJson = require("../config.json");
const APIBASE = new URL(configJson.apiBase);
const APIURL = APIBASE + "api/baskets/";

export default async function basketRemoveDocumentRequest(basketId, resources) {
  return await axios.post(
    APIURL + basketId + "/remove",
    {
      resources: resources
    },
    {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("Bearer")
      }
    }
  );
}
