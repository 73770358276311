import { createContext } from "react";

export const MobileDrawerContext = createContext({
  mobileDrawerState: false,
  showMobileDrawerButton: false,
  mobileDrawerDispatch: () => {
  },
  showMobileDrawerButtonDispatch: () => {
  }
});
