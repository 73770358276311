import React, { useContext } from "react";
import { Grid, IconButton, Tooltip, Typography } from "@material-ui/core";
import { strings } from "../../localStrings";
import SearchIcon from "@material-ui/icons/Search";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import EditIcon from "@material-ui/icons/Edit";
import GetAppIcon from "@material-ui/icons/GetApp";
import DeleteIcon from "@material-ui/icons/Delete";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { SimilarBasketsContext } from "../../context/SimilarBasketsContext";
import downloadBasket from "../../utility/downloadBasket";
import { LoginContext } from "../../context/LoginContext";
import { SessionContext } from "../../context/SessionContext";
// Import useStyles last!
import useStyles from "../../useStyles";

export default function BasketEntrySummary({
  onRename,
  onDelete,
  basketTitle,
  basketId,
  sharedFrom
}) {
  const classes = useStyles();
  const history = useHistory();

  const similarBasketsContext = useContext(SimilarBasketsContext);
  const addSimilarBasket = similarBasketsContext.addSimilarBasket;
  const setSimilarBaskets = similarBasketsContext.setSimilarBaskets;

  const loginContext = useContext(LoginContext);
  const loginStateDispatcher = loginContext.loginStateDispatcher;
  const sessionContext = useContext(SessionContext);
  const sessionExpiredDispatcher = sessionContext.sessionExpiredDispatcher;

  const handleSearch = () => {
    setSimilarBaskets([{ id: basketId, label: basketTitle }]);
    history.push("/");
  };

  const handleAddToSearch = () => {
    addSimilarBasket(basketId, basketTitle);
  };

  const handleDownloadBasket = downloadBasket(
    basketId,
    sessionExpiredDispatcher,
    loginStateDispatcher
  );

  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <Typography variant="h6" component="h2">
          <Tooltip title={strings.basketSearch}>
            <IconButton
              size={"small"}
              onClick={e => {
                e.stopPropagation();
                handleSearch();
              }}
              className={classes.leftIcon}
            >
              <SearchIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={strings.addBasketSearch}>
            <IconButton
              size={"small"}
              onClick={e => {
                e.stopPropagation();
                handleAddToSearch();
              }}
              className={classes.leftIcon}
            >
              <ZoomInIcon />
            </IconButton>
          </Tooltip>
          {!sharedFrom && (
            <Tooltip title={strings.rename}>
              <IconButton
                size={"small"}
                onClick={e => {
                  e.stopPropagation();
                  onRename();
                }}
                className={classes.leftIcon}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          )}
          {basketTitle}
        </Typography>
        {sharedFrom && (
          <Typography variant={"subtitle2"}>
            {strings.owner + ": " + sharedFrom}
          </Typography>
        )}
      </Grid>
      <Grid item xs={6}>
        <Typography className={classes.basketIcons}>
          <Tooltip title={strings.downloadBasket}>
            <IconButton
              className={classes.rightMargin}
              size={"small"}
              onClick={e => {
                e.stopPropagation();
                handleDownloadBasket();
              }}
            >
              <GetAppIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={strings.delete}>
            <IconButton
              size={"small"}
              onClick={e => {
                e.stopPropagation();
                onDelete();
              }}
              className={classes.rightIcon}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Typography>
      </Grid>
    </Grid>
  );
}

BasketEntrySummary.propTypes = {
  onRename: PropTypes.func,
  basketTitle: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  basketId: PropTypes.string.isRequired,
  sharedFrom: PropTypes.string
};
