import { Button, Hidden } from "@material-ui/core/";
import InputIcon from "@material-ui/icons/Input";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { strings } from "../localStrings";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import { LoginContext } from "../context/LoginContext";
// import useStyles last!
import useStyles from "../useStyles";

/**
 * Link to LoginSite.
 * @type {React.ForwardRefExoticComponent<React.PropsWithoutRef<{}> & React.RefAttributes<unknown>>}
 */
const LoginLink = React.forwardRef((props, ref) => (
  <Link innerRef={ref} to="/login" {...props} />
));

/**
 * Renders the login button. If logged out, it links to the LoginSite. If logged in, it loggs the user out.
 *
 * @returns {*}
 */
function LoginButton() {
  const classes = useStyles();
  const loginContext = useContext(LoginContext);
  const loginState = loginContext.loginState;
  const loginStateDispatcher = loginContext.loginStateDispatcher;

  const handleLogout = () => {
    loginStateDispatcher({ type: "logout" });
    localStorage.setItem("Bearer", "");
  };

  if (loginState) {
    return (
      <div>
        <Hidden smDown>
          <Button
            className={classes.button}
            href="/"
            onClick={handleLogout}
            color={"inherit"}
          >
            <ExitToAppIcon className={classes.leftIcon} />
            {strings.logOut}
          </Button>
        </Hidden>
        <Hidden mdUp>
          <IconButton onClick={handleLogout} color={"inherit"}>
            <ExitToAppIcon />
          </IconButton>
        </Hidden>
      </div>
    );
  } else {
    return (
      <div>
        <Hidden smDown>
          <Button
            className={classes.button}
            href="#"
            component={LoginLink}
            color={"inherit"}
          >
            <InputIcon className={classes.leftIcon} />
            {strings.logIn}
          </Button>
        </Hidden>
        <Hidden mdUp>
          <IconButton component={LoginLink} color={"inherit"}>
            <InputIcon />
          </IconButton>
        </Hidden>
      </div>
    );
  }
}

export default LoginButton;
