import React from "react";
import { Chip } from "@material-ui/core";
import PropTypes from "prop-types";
// Import useStyles last!
import useStyles from "../useStyles";

/**
 * Renders multiple chips from an array of values.
 *
 * @param chipData An array of objects with content {id, label}.
 * @param handleDelete Callback function to delete the object with the given key.
 * @param type Type of Chip, to set a background color.
 * @returns {*}
 * @constructor
 */
function ChipArray({ chipData, handleDelete, type }) {
  const classes = useStyles();

  const colors = { like: "#0099ff", basket: "#ff9900" };

  return (
    <div className={classes.chipArrayRoot}>
      {chipData.map(data => {
        return (
          <Chip
            key={data.id}
            label={data.label}
            onDelete={() => handleDelete(data.id)}
            className={classes.chipArrayChip}
            style={{ backgroundColor: colors[type] }}
          />
        );
      })}
    </div>
  );
}

ChipArray.propTypes = {
  chipData: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleDelete: PropTypes.func.isRequired,
  type: PropTypes.string
};

export default React.memo(ChipArray);
