import React, { useContext, useEffect } from "react";
import { Redirect } from "react-router-dom";
import SharedBasketView from "../components/baskets/SharedBasketView";
import { LoginContext } from "../context/LoginContext";
import { MobileDrawerContext } from "../context/MobileDrawerContext";
import { SessionContext } from "../context/SessionContext";

/**
 * Renders the baskets of the currently logged in user.
 *
 * @returns {*}
 * @constructor
 */
function SharedBasketSite() {
  const loginContext = useContext(LoginContext);
  const loginState = loginContext.loginState;
  const notLoggedInDispatcher = useContext(SessionContext)
    .notLoggedInDispatcher;

  const showMobileDrawerButton = useContext(MobileDrawerContext)
    .showMobileDrawerButtonDispatch;

  useEffect(() => {
    showMobileDrawerButton({ type: "hide" });
  }, [showMobileDrawerButton]);

  if (loginState) {
    return <SharedBasketView />;
  } else {
    notLoggedInDispatcher({ type: "set" });
    return (
      // Redirect to Homepage if not logged in
      <Redirect to={"/"} />
    );
  }
}

export default React.memo(SharedBasketSite);
