import React, { useContext, useEffect, useState } from "react";
import { Divider, Grid, Paper } from "@material-ui/core";
import DocumentView from "../components/documents/DocumentView";
import { Redirect } from "react-router-dom";
import documentRequest from "../requests/documentRequest";
import basketRequest from "../requests/basketRequest";
import DocumentDetails from "../components/documents/DocumentDetails";
import { useParams } from "react-router-dom";
import { LoginContext } from "../context/LoginContext";
import axios from "axios";
import { MobileDrawerContext } from "../context/MobileDrawerContext";
import { SessionContext } from "../context/SessionContext";
import { LocationContext } from "../context/LocationContext";
import equal from "deep-equal";
import { requestError } from "../utility/requestError";
import { useQuery } from "react-query";
import qs from "qs";
import Wordcloud from "../components/Wordcloud";
import useStyles from "../useStyles";

const configJson = require("../config.json");
const APIBASE = new URL(configJson.apiBase);

/**
 * Prototype for response for document details request.
 * @type {{institution: string, "study-title": string, author: string, "study-id": string, "instrument-type": string, "publication-date": string, "research-topic": string[], id: string, title: string, "document-type": string, url: string}}
 */
const detailsBody = {
  "author": "",
  "document-type": "",
  "id": "",
  "institution": "",
  "instrument-type": "",
  "publication-date": "",
  "research-topic": ["", ""],
  "study-title": "",
  "study-id": "",
  "title": "",
  "url": ""
};

/**
 * Renders the site for displaying the details of a single document.
 *
 * @returns {*}
 */
function DocumentSite() {
  const classes = useStyles();

  const params = useParams(); // React Router
  const pathname = useContext(LocationContext).pathname;
  const search = useContext(LocationContext).search;
  const snippet = qs.parse(search, { ignoreQueryPrefix: true }).search;
  const [document, setDocument] = useState(detailsBody);
  const docId = params.docId; // Parse parameters from url
  const mode = pathname.includes("context") ? "elabour-contexts" : "elabour";
  const [baskets, setBaskets] = useState([]);
  const [termVectors, setTermVectors] = useState({});

  const loginContext = useContext(LoginContext);
  const loginState = loginContext.loginState;
  const loginStateDispatcher = loginContext.loginStateDispatcher;
  const sessionContext = useContext(SessionContext);
  const notLoggedInDispatcher = sessionContext.notLoggedInDispatcher;
  const sessionExpiredDispatcher = sessionContext.sessionExpiredDispatcher;
  const notFound = sessionContext.notFound;
  const notFoundDispatcher = sessionContext.notFoundDispatcher;

  const showMobileDrawerButton = useContext(MobileDrawerContext)
    .showMobileDrawerButtonDispatch;

  useEffect(() => {
    showMobileDrawerButton({ type: "hide" });
  }, [showMobileDrawerButton]);

  // Send request for the document details and handle the response.
  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    documentRequest(mode, docId, source)
      .then(function(response) {
        setDocument(response.data["_source"]);
        setTermVectors(response.data["term_vectors"]);
      })
      .catch(function(error) {
        if (error.response) {
          if (error.response.status === 401) {
            sessionExpiredDispatcher({ type: "expired" });
            localStorage.setItem("Bearer", "");
            loginStateDispatcher({ type: "logout" });
          } else if (
            error.response.status === 403 ||
            error.response.status === 404
          ) {
            notFoundDispatcher({ type: "set" });
          } else {
            console.log(error.response);
          }
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log(error);
        }
      });

    return () => {
      source.cancel();
    };
  }, [
    docId,
    loginStateDispatcher,
    mode,
    notFoundDispatcher,
    sessionExpiredDispatcher
  ]);

  const basketInfo = useQuery("baskets", basketRequest, {
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    retry: false,
    refetchInterval: false,
    onError: requestError(sessionExpiredDispatcher, loginStateDispatcher),
    refetchOnMount: false
  });

  useEffect(() => {
    if (
      basketInfo.data &&
      basketInfo.data.data &&
      !equal(baskets, basketInfo.data.data)
    ) {
      setBaskets(basketInfo.data.data);
    }
  }, [basketInfo.data, baskets, setBaskets]);

  // Load pdf
  const documentUrl = `${APIBASE}api/file/${mode}/${docId}`;

  const [blob, setBlob] = useState(new Blob(["Empty"], { type: "text/plain" }));
  const [fileUrl, setFileUrl] = useState(URL.createObjectURL(blob));
  const [loading, setLoading] = useState(true);
  const [url, setUrl] = useState(document["url"]);

  useEffect(() => {
    if (url !== document["url"]) {
      setUrl(document["url"]);
    }
  }, [url, document]);

  // Sends request for the document and saves response as blob.
  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const fetchData = async () => {
      await axios
        .get(documentUrl, {
          responseType: "blob",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("Bearer")
          },
          cancelToken: source.token
        })
        .then(result => {
          setBlob(result.data);
          setFileUrl(URL.createObjectURL(result.data));
        })
        .catch(error => {
          if (error.response) {
            if (error.response.status === 401) {
              sessionExpiredDispatcher({ type: "expired" });
              localStorage.setItem("Bearer", "");
              loginStateDispatcher({ type: "logout" });
            } else if (error.response.status === 404) {
              setBlob(new Blob(["NotFound"], { type: "text/error" }));
            } else {
              console.log(error.response);
            }
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log(error);
          }
        });
      setLoading(false);
    };

    if (blob.type !== "application/pdf" && blob.type !== "text/error") {
      fetchData();
    }

    return () => {
      source.cancel();
    };
  }, [documentUrl, blob, sessionExpiredDispatcher, loginStateDispatcher]);

  if (loginState) {
    if (notFound) {
      return (
        // Redirect to Homepage if not logged in
        <Redirect to={"/"} />
      );
    }
    return (
      <div>
        <Grid container>
          <Grid item xs={12} lg={6}>
            <DocumentDetails
              document={document}
              mode={mode}
              baskets={baskets}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Paper className={classes.fillHeight}>
              <Wordcloud termVectors={termVectors} />
            </Paper>
          </Grid>
        </Grid>
        <Divider />
        <DocumentView
          fileUrl={fileUrl}
          loading={loading}
          blob={blob}
          search={snippet}
        />
      </div>
    );
  } else {
    notLoggedInDispatcher({ type: "set" });
    return (
      // Redirect to Homepage if not logged in
      <Redirect to={"/"} />
    );
  }
}

export default React.memo(DocumentSite);
