import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";

/**
 * Pdf viewer.
 *
 * @param fileUrl
 * @param backend
 * @param search
 * @returns {*}
 * @constructor
 */
function PdfView({ fileUrl, backend, search }) {
  const viewRef = useRef(null);

  useEffect(() => {
    const pdfBackend = new backend();

    pdfBackend.init(fileUrl, viewRef.current, search);
  }, [backend, fileUrl, search]);

  return (
    <div
      ref={viewRef}
      id={"viewer"}
      style={{ width: "100%", height: "1000px" }}
    />
  );
}

PdfView.propTypes = {
  fileUrl: PropTypes.string.isRequired,
  backend: PropTypes.any.isRequired,
  search: PropTypes.string
};

export default PdfView;
