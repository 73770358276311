import { makeStyles } from "@material-ui/styles";

const drawer_width = "15vw";

/**
 * CSS styles.
 * @type {StylesHook<Styles<DefaultTheme, {}, string>>}
 */
const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh"
  },
  logo: {
    maxWidth: "100%",
    maxHeight: "100px"
  },
  button: {
    margin: theme.spacing(1)
  },
  leftIcon: {
    marginRight: theme.spacing(1)
  },
  rightIcon: {
    marginLeft: theme.spacing(1)
  },
  firstButton: {
    margin: theme.spacing(1),
    marginLeft: "auto"
  },
  footer: {
    marginTop: "auto",
    marginBottom: theme.spacing(0)
  },
  above_drawer: {
    zIndex: theme.zIndex.drawer + 1
  },
  drawer: {
    minWidth: "300px",
    position: "relative",
    width: drawer_width
  },
  searchBar: {
    marginTop: "20%",
    marginBottom: "20%"
  },
  drawerPanel: {
    margin: "0 !important"
  },
  paper: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.up("lg")]: {
      padding: theme.spacing(4, 8),
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2)
    }
  },
  paperTable: {
    padding: theme.spacing(4, 4)
  },
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  docView: {
    width: "100%",
    minHeight: "1000px",
    overflow: "auto"
  },
  FilterItemText: {
    padding: theme.spacing(0, 2, 0, 0),
    marginLeft: theme.spacing(-3)
  },
  FilterItemCount: {
    marginLeft: theme.spacing(-1)
  },
  YearFilter: {
    margin: theme.spacing(3, 3, 0)
  },
  drawerContent: {
    maxHeight: theme.spacing(80),
    overflow: "auto"
  },
  textField: {
    marginLeft: theme.spacing(8),
    marginRight: theme.spacing(8)
  },
  progress: {
    margin: theme.spacing(2)
  },
  basketText: {
    marginLeft: theme.spacing(-3)
  },
  expansionPanelHeading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  expansionPanelDetailsNoPadding: {
    padding: theme.spacing(0)
  },
  divInline: {
    display: "inline-block"
  },
  card: {
    margin: theme.spacing(2)
  },
  resultCard: {
    margin: theme.spacing(2, 0)
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1)
  },
  searchRoot: {
    display: "flex"
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex"
    }
  },
  grow: {
    flexGrow: 1
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  },
  centeredText: {
    "text-align": "center"
  },
  chipArrayRoot: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    padding: theme.spacing(0.5)
  },
  chipArrayChip: {
    margin: theme.spacing(0.5)
  },
  snippet: {
    paddingTop: theme.spacing(1)
  },
  background: {
    background: theme.palette.secondary.light
  },
  backgroundLighter: {
    background: theme.palette.secondary.lighter
  },
  basketIcons: {
    textAlign: "right"
  },
  helperText: {
    color: "black"
  },
  documentNotFound: {
    margin: theme.spacing(2),
    padding: theme.spacing(1)
  },
  searchInput: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "auto"
  },
  iconButton: {
    padding: 10
  },
  divider: {
    height: 28,
    margin: 4
  },
  tooltip: {
    whiteSpace: "pre-line"
  },
  select: {
    marginLeft: theme.spacing(1)
  },
  bold: {
    fontWeight: 500
  },
  loading: {
    minHeight: "30vh"
  },
  horizontalCenterButton: {
    display: "block",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginRight: "auto",
    marginLeft: "auto"
  },
  buttonContainer: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  basketButtons: {
    minWidth: theme.spacing(14)
  },
  rightMargin: {
    marginRight: theme.spacing(2)
  },
  shareBasketButton: {
    marginLeft: theme.spacing(1)
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  },
  fillHeight: {
    height: "100%"
  },
  spacing: {
    margin: theme.spacing(3)
  },
  modeColor: {},
  likeColor: {
    backgroundColor: "#0099ff"
  },
  facetColor: {
    backgroundColor: "#009933"
  },
  basketColor: {
    backgroundColor: "#ff9900"
  }
}));

export default useStyles;
