import { List, Typography } from "@material-ui/core";
import { FilterEntry } from "./FilterEntry";
import React from "react";
import { strings } from "../../../localStrings";
import PropTypes from "prop-types";
import useStyles from "../../../useStyles";

/**
 * Renders the filter options for the facet filter.
 *
 * @param setFilterChanged Callback function to be called when the filter is changed.
 * @param facet Object with the possible facet values.
 * @param checked Array with the selected filter values.
 * @param setChecked Callback function for checked.
 * @param docCounts Number of documents for each filter option.
 * @param initial True, if no search was executed.
 * @returns {*}
 * @constructor
 */
function FacetFilter({
  setFilterChanged,
  facet,
  checked,
  setChecked,
  docCounts = {},
  initial
}) {
  const classes = useStyles();

  let bucketDocCount = [];

  if (!initial) {
    for (let i = 0; i < facet.buckets.length; i++) {
      let key = facet.buckets[i].key;
      bucketDocCount[i] = docCounts[key];
    }
  } else {
    for (let i = 0; i < facet.buckets.length; i++) {
      bucketDocCount[i] = facet.buckets[i].doc_count;
    }
  }

  const handleToggle = value => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
    setFilterChanged(true);
  };

  if (
    facet.buckets.length === 0 ||
    (facet.buckets.length === 1 &&
      (facet.buckets[0].key === "<OTHER>" || facet.buckets[0] === ""))
  ) {
    return (
      <Typography className={classes.centeredText}>
        {strings.searchDrawer.noFilter}
      </Typography>
    );
  } else {
    return (
      <List className={classes.drawerContent}>
        {facet.buckets.map((value, index) => (
          <FilterEntry
            key={value.key}
            entry={value}
            onToggle={handleToggle}
            checked={checked}
            docCount={bucketDocCount[index] || 0}
          />
        ))}
      </List>
    );
  }
}

FacetFilter.propTypes = {
  setFilterChanged: PropTypes.func.isRequired,
  facet: PropTypes.object.isRequired,
  checked: PropTypes.arrayOf(PropTypes.string).isRequired,
  setChecked: PropTypes.func.isRequired,
  docCounts: PropTypes.object.isRequired,
  initial: PropTypes.bool
};

export default React.memo(FacetFilter);
