/**
 * Parses the given date string and produces a new date string according to the current locale.
 *
 * @param datestring The date formated as "yyyy-MM-ddThh:mm:ss.ffffff".
 * @returns {string}
 */
export function formatDate(datestring) {
  const datePart = datestring.split("T")[0];
  const timePart = datestring.split("T")[1].split(".")[0];

  const year = parseInt(datePart.split("-")[0]);
  const month = parseInt(datePart.split("-")[1]) - 1; // Months are 0 indexed in Date Object
  const day = parseInt(datePart.split("-")[2]);
  const hour = parseInt(timePart.split(":")[0]);
  const minute = parseInt(timePart.split(":")[1]);
  const second = parseInt(timePart.split(":")[2]);

  const date = new Date(year, month, day, hour, minute, second);

  return date.toLocaleString();
}
