/*eslint no-unused-vars:0*/
import { Grid, Typography } from "@material-ui/core";
import { strings } from "../../localStrings";
import React, { useContext, useEffect, useState } from "react";
import { LoginContext } from "../../context/LoginContext";
import { SessionContext } from "../../context/SessionContext";
import { requestError } from "../../utility/requestError";
import equal from "deep-equal";
import { LanguageContext } from "../../context/LanguageContext";
import sharedBasketRequest from "../../requests/sharedBasketRequest";
import { useQuery } from "react-query";
import SharedBasketEntry from "./SharedBasketEntry";
// import useStyles last!
import useStyles from "../../useStyles";

/**
 * Renders the Baskets of the current user and provides methods to create, delete and edit them.
 *
 * @returns {*}
 */
function BasketView() {
  const classes = useStyles();

  const language = useContext(LanguageContext); // Rerender on language change

  const loginContext = useContext(LoginContext);
  const loginStateDispatcher = loginContext.loginStateDispatcher;
  const sessionContext = useContext(SessionContext);
  const sessionExpiredDispatcher = sessionContext.sessionExpiredDispatcher;

  const [baskets, setBaskets] = useState([]);
  const [updatedBaskets, setUpdatedBaskets] = useState(true);

  const basketInfo = useQuery("sharedBaskets", sharedBasketRequest, {
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    retry: false,
    refetchInterval: false,
    onError: requestError(sessionExpiredDispatcher, loginStateDispatcher),
    refetchOnMount: false
  });

  useEffect(() => {
    if (
      basketInfo.data &&
      basketInfo.data.data &&
      !equal(baskets, basketInfo.data.data)
    ) {
      setBaskets(basketInfo.data.data);
    }
    setUpdatedBaskets(false);
  }, [updatedBaskets, basketInfo.data, baskets]);

  return (
    <div className={classes.paper}>
      <div>
        <Typography
          variant="h5"
          component="h2"
          gutterBottom
          className={classes.spacing}
        >
          {strings.userSite.baskets}
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            {baskets.map(value => {
              return (
                <SharedBasketEntry
                  basketId={value._id}
                  basketTitle={value.title}
                  key={value._id}
                  setUpdatedBaskets={setUpdatedBaskets}
                  updatedBaskets={updatedBaskets}
                  sharedFrom={value.user}
                />
              );
            })}
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default React.memo(BasketView);
