import axios from "axios";

const configJson = require("../config.json");
const APIBASE = new URL(configJson.apiBase);
const APIURL = APIBASE + "api/token";

export default async function loginRequest(username, password) {
  return await axios.post(APIURL, {
    name: username,
    password: password
  });
}
