import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import { Drawer, Hidden, List } from "@material-ui/core";
import basketRequest from "../../requests/basketRequest";
import facetsRequest from "../../requests/facetsRequest";
import DrawerContent from "./DrawerContent";
import { LoginContext } from "../../context/LoginContext";
import equal from "deep-equal";
import { MobileDrawerContext } from "../../context/MobileDrawerContext";
import { SessionContext } from "../../context/SessionContext";
import { requestError } from "../../utility/requestError";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import sharedBasketRequest from "../../requests/sharedBasketRequest";
// import useStyles last!
import useStyles from "../../useStyles";

/**
 * Prototype of a filter entry.
 *
 * @type {{doc_count_error_upper_bound: number, sum_other_doc_count: number, buckets: any[]}}
 */
const filterEntry = {
  buckets: Array(0),
  doc_count_error_upper_bound: 0,
  sum_other_doc_count: 0
};

/**
 * Prototype of a filter body.
 *
 */
const filterBody = {
  "exploration-type": filterEntry,
  "document-class": filterEntry,
  "research-area": filterEntry,
  "study-title": filterEntry,
  "case": filterEntry,
  "wave": filterEntry
};

const filterOrder = [
  "exploration-type",
  "document-class",
  "research-area",
  "study-title",
  "case",
  "wave"
];

/**
 * The search drawer contains the filter options for the search. On medium and larger screens it is displayed on
 * every site the has a search box, on smaller screens it is hidded on load and can be shown with the menu button.
 *
 * @param mode Document or context search.
 * @param filterCallback Callback function that applies the filters to the next search query.
 * @param filterConfig The currently applied filters.
 * @param aggregations The elasticsearch aggregations for the curent search term and filter options.
 * @param baskets The baskets of the current user.
 * @param setBaskets Callback function for baskets.
 * @param resetFilters Calback function to reset the search state.
 * @param initial True, if no search was executed.
 * @returns {*}
 * @constructor
 */
function SearchDrawer({
  mode,
  filterCallback,
  filterConfig,
  aggregations,
  baskets,
  setBaskets,
  resetFilters,
  initial
}) {
  const classes = useStyles();

  const history = useHistory();

  const mobileDrawerContext = useContext(MobileDrawerContext);
  const mobileDrawerDispatch = mobileDrawerContext.mobileDrawerDispatch;
  const mobileDrawerState = mobileDrawerContext.mobileDrawerState;

  const loginContext = useContext(LoginContext);
  const loginStateDispatcher = loginContext.loginStateDispatcher;
  const sessionContext = useContext(SessionContext);
  const sessionExpiredDispatcher = sessionContext.sessionExpiredDispatcher;

  const [yearFilterFrom, setYearFilterFrom] = useState(
    filterConfig.year && filterConfig.year.from ? filterConfig.year.from : ""
  );
  const [yearFilterTo, setYearFilterTo] = useState(
    filterConfig.year && filterConfig.year.to ? filterConfig.year.to : ""
  );
  const [apiFacets, setApiFacets] = useState(filterBody);
  const [facets, setFacets] = useState(
    filterConfig.facets ? filterConfig.facets : {}
  );

  const YEAR_MIN_VALUE = 1950;

  let maxYear = new Date().getFullYear();
  let minYear = YEAR_MIN_VALUE;
  if (aggregations) {
    maxYear = aggregations["collection-date_max"]
      ? aggregations["collection-date_max"].value
      : new Date().getFullYear();
    minYear = aggregations["collection-date_min"]
      ? aggregations["collection-date_min"].value
      : YEAR_MIN_VALUE;
  }

  const [studies, setStudies] = useState(
    facets["study-title"] === undefined
      ? []
      : Object.values(facets["study-title"])
  );
  const [explorationType, setExplorationType] = useState(
    facets["exploration-type"] === undefined
      ? []
      : Object.values(facets["exploration-type"])
  );
  const [documentClass, setDocumentClass] = useState(
    facets["document-class"] === undefined
      ? []
      : Object.values(facets["document-class"])
  );
  const [researchArea, setResearchArea] = useState(
    facets["research-area"] === undefined
      ? []
      : Object.values(facets["research-area"])
  );
  const [caseFacet, setCaseFacet] = useState(
    facets["case"] === undefined ? [] : Object.values(facets["case"])
  );
  const [wave, setWave] = useState(
    facets["wave"] === undefined ? [] : Object.values(facets["wave"])
  );
  const [basketFilter, setBasketFilter] = useState(
    filterConfig.baskets ? Object.values(filterConfig.baskets) : []
  );

  const [documentCounts, setDocumentCounts] = useState(() => {
    const state = {};
    for (let key in filterBody) {
      state[key] = {};
    }
    return state;
  });

  const [filterChanged, setFilterChanged] = useState(false);

  useEffect(() => {
    if (aggregations) {
      function getCountsFromBuckets(aggregation) {
        let counts = {};
        if (aggregation === undefined) {
          return counts;
        }
        let buckets = aggregation["buckets"];

        for (let bucket of buckets) {
          counts[bucket.key] = bucket.doc_count;
        }
        return counts;
      }

      function getSubCountsFromBuckets(aggregation) {
        let subCounts = {};
        if (aggregation === undefined) {
          return subCounts;
        }

        const buckets = aggregation["buckets"];

        for (let bucket of buckets) {
          for (let key in filterBody) {
            const bucketCounts = getCountsFromBuckets(bucket[key]);
            if (!equal(bucketCounts, {})) {
              if (subCounts[bucket.key] === undefined) {
                subCounts[bucket.key] = {};
              }
              subCounts[bucket.key][key] = bucketCounts;
            }
          }
        }
        return subCounts;
      }

      for (let key in filterBody) {
        const newCounts = getCountsFromBuckets(aggregations[key]);
        const newSubCounts = getSubCountsFromBuckets(aggregations[key]);
        if (!equal(newSubCounts, {})) {
          newCounts.subCounts = newSubCounts;
        }
        if (!equal(documentCounts[key], newCounts)) {
          setDocumentCounts(s => {
            s[key] = newCounts;
            return { ...s };
          });
        }
      }
    }
  }, [aggregations, documentCounts]);

  // Fetch facets.
  const facetInfo = useQuery(mode, facetsRequest, {
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    retry: false,
    refetchInterval: false,
    onError: requestError(sessionExpiredDispatcher, loginStateDispatcher),
    refetchOnMount: false
  });

  if (
    facetInfo.data &&
    facetInfo.data.data &&
    facetInfo.data.data["aggregations"] &&
    !equal(apiFacets, facetInfo.data.data["aggregations"])
  ) {
    setApiFacets(facetInfo.data.data["aggregations"]);
  }

  // Fetch baskets.
  const basketInfo = useQuery("baskets", basketRequest, {
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    retry: false,
    refetchInterval: false,
    onError: requestError(sessionExpiredDispatcher, loginStateDispatcher),
    refetchOnMount: false
  });

  const sharedBasketInfo = useQuery("sharedBaskets", sharedBasketRequest, {
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    retry: false,
    refetchInterval: false,
    onError: requestError(sessionExpiredDispatcher, loginStateDispatcher),
    refetchOnMount: false
  });

  useEffect(() => {
    if (
      basketInfo.data &&
      basketInfo.data.data &&
      sharedBasketInfo.data &&
      sharedBasketInfo.data.data
    ) {
      const ownBaskets = basketInfo.data.data;
      ownBaskets.forEach(element => {
        element.shared = false;
      });
      const sharedBaskets = sharedBasketInfo.data.data;
      sharedBaskets.forEach(element => {
        element.shared = true;
      });
      const newBaskets = [...ownBaskets, ...sharedBaskets];
      if (!equal(baskets, newBaskets)) {
        setBaskets(newBaskets);
      }
    }
  }, [basketInfo.data, baskets, setBaskets, sharedBasketInfo.data]);

  useEffect(() => {
    const apply_filters = updatedFacets => {
      let filter = {};

      //Build filter object only of used filters
      if (yearFilterTo !== "" || yearFilterFrom !== "") {
        filter["year"] = {};

        if (yearFilterTo !== "") {
          filter["year"]["to"] = yearFilterTo;
        }

        if (yearFilterFrom !== "") {
          filter["year"]["from"] = yearFilterFrom;
        }
      }

      const areFacetsDefined =
        updatedFacets &&
        (typeof updatedFacets["study-title"] !== "undefined" ||
          typeof updatedFacets["exploration-type"] !== "undefined" ||
          typeof updatedFacets["document-class"] !== "undefined" ||
          typeof updatedFacets["research-area"] !== "undefined" ||
          typeof updatedFacets["case"] !== "undefined" ||
          typeof updatedFacets["wave"] !== "undefined");
      if (areFacetsDefined) {
        filter["facets"] = updatedFacets;
      }

      if (basketFilter && basketFilter.length > 0) {
        filter["baskets"] = basketFilter;
      }

      filterCallback(filter);
    };

    let newFacets = {
      "study-title": studies.length === 0 ? undefined : studies,
      "exploration-type":
        explorationType.length === 0 ? undefined : explorationType,
      "document-class": documentClass.length === 0 ? undefined : documentClass,
      "research-area": researchArea.length === 0 ? undefined : researchArea,
      "case": caseFacet.length === 0 ? undefined : caseFacet,
      "wave": wave.length === 0 ? undefined : wave
    };

    if (!equal(facets, newFacets)) {
      setFacets(newFacets);
    } else {
      newFacets = facets;
    }

    if (filterChanged) {
      setFilterChanged(false);
      apply_filters(newFacets);
    }
  }, [
    filterChanged,
    yearFilterTo,
    yearFilterFrom,
    facets,
    basketFilter,
    filterCallback,
    studies,
    explorationType,
    documentClass,
    researchArea,
    caseFacet,
    wave
  ]);

  const removeFilters = useCallback(() => {
    resetFilters();
    history.push("/");
  }, [history, resetFilters]);

  const drawerContent = useMemo(() => {
    return (
      <DrawerContent
        baskets={baskets}
        initial={initial}
        filterConfig={{
          basketFilter,
          yearFilterFrom,
          yearFilterTo,
          maxYear,
          minYear,
          YEAR_MIN_VALUE,
          apiFacets,
          "document-class": documentClass,
          "exploration-type": explorationType,
          "research-area": researchArea,
          "study-title": studies,
          "case": caseFacet,
          "wave": wave
        }}
        facetCounts={documentCounts}
        filterCallbacks={{
          setBasketFilter,
          setFilterChanged,
          setYearFilterFrom,
          setYearFilterTo,
          "exploration-type": setExplorationType,
          "document-class": setDocumentClass,
          "research-area": setResearchArea,
          "study-title": setStudies,
          "case": setCaseFacet,
          "wave": setWave
        }}
        resetFilters={removeFilters}
        filterOrder={filterOrder}
      />
    );
  }, [
    baskets,
    initial,
    basketFilter,
    yearFilterFrom,
    yearFilterTo,
    maxYear,
    minYear,
    apiFacets,
    studies,
    documentClass,
    explorationType,
    researchArea,
    caseFacet,
    wave,
    documentCounts,
    removeFilters
  ]);

  return (
    <div>
      <Hidden smDown>
        <div className={classes.drawer}>
          <List>{drawerContent}</List>
        </div>
      </Hidden>
      <Hidden mdUp>
        <Drawer
          open={mobileDrawerState}
          onClose={() => mobileDrawerDispatch({ type: "hide" })}
        >
          {drawerContent}
        </Drawer>
      </Hidden>
    </div>
  );
}

function areEqual(prevProps, nextProps) {
  return equal(prevProps, nextProps);
}

export default React.memo(SearchDrawer, areEqual);
