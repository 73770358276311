import axios from "axios";

const configJson = require("../config.json");
const APIBASE = new URL(configJson.apiBase);

export default async function SearchRequest(
  search_term,
  page_offset,
  page_size,
  mode,
  filters,
  likeDocs,
  likeBaskets
) {
  let requestinit = buildRequest(
    search_term,
    page_offset,
    page_size,
    filters,
    likeDocs,
    likeBaskets
  );

  return await axios.post(
    APIBASE + "api/search/" + mode + "/query",
    requestinit,
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("Bearer")
      },
      mode: "CORS"
    }
  );
}

function buildRequest(
  search_term,
  page_offset,
  page_size,
  filter,
  likeDocs,
  likeBaskets
) {
  if (!filter) {
    filter = {
      year: {
        to: "",
        from: ""
      },
      facets: {},
      baskets: []
    };
  } else {
    filter = fixFacets(filter);
  }

  let payload = {
    overview: false,
    term: search_term,
    offset: page_offset,
    pageSize: page_size,
    filters: {
      baskets: filter.baskets ? filter.baskets : [],
      collectionDate: {
        from: filter.year && filter.year.from ? filter.year.from : "",
        to: filter.year && filter.year.to ? filter.year.to : ""
      },
      facets: filter.facets ? filter.facets : {}
    }
  };

  if (likeDocs !== undefined && likeDocs.length > 0) {
    const searchLike = JSON.parse(sessionStorage.getItem("searchlike"));
    payload["like_docs"] = searchLike.map(e => {
      let likeDoc = {};
      likeDoc.index = e.index;
      likeDoc.id = e.id;
      return likeDoc;
    });
  }

  if (likeBaskets !== undefined && likeBaskets.length > 0) {
    const basketLike = JSON.parse(sessionStorage.getItem("basketlike"));
    payload["like_baskets"] = basketLike.map(e => {
      return e.id;
    });
  }

  return JSON.stringify(payload);
}

/**
 * Function fixes wrong format caused by search-ui's URL parsing
 * @param filter The parsed filter object
 * @returns The corrected parsed filter object
 */
function fixFacets(filter) {
  for (let key in filter.facets) {
    if (filter.facets[key] !== undefined && !isValueArray(filter.facets[key])) {
      let array = [];

      for (let subkey in filter.facets[key]) {
        array.push(filter.facets[key][subkey]);
      }

      filter.facets[key] = array;
    }
  }

  return filter;
}

function isValueArray(value) {
  //For old browsers
  if (typeof Array.isArray === "undefined") {
    return Object.prototype.toString.call(value) === "[object Array]";
  } else {
    return Array.isArray(value);
  }
}
