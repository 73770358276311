import React from "react";
import {
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemText
} from "@material-ui/core";
import { strings } from "../../localStrings";
import PropTypes from "prop-types";

export function SelectBasketDialog({ open, onClose, baskets, onItemClick }) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle id={"basket-dialog-title"}>
        {strings.documentDetails.selectBasket}
      </DialogTitle>
      <List>
        {baskets.map(
          basket =>
            !basket.shared && (
              <ListItem
                button
                onClick={() => onItemClick(basket._id)}
                key={basket._id}
              >
                <ListItemText primary={basket.title} />
              </ListItem>
            )
        )}
        <ListItem button onClick={() => onItemClick(0)}>
          <ListItemText primary={strings.newBasket} />
        </ListItem>
      </List>
    </Dialog>
  );
}

SelectBasketDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  baskets: PropTypes.arrayOf(PropTypes.object),
  onItemClick: PropTypes.func.isRequired
};
