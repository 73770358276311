const configJson = require("../config.json");
const APIBASE = new URL(configJson.apiBase);

export function fileDownload(path, ticket, filename = "") {
  const url = new URL(APIBASE + path);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename);
  link.click();
}
