import React, { useContext, useEffect } from "react";
import SearchApplication from "../components/search/SearchApplication";
import { MobileDrawerContext } from "../context/MobileDrawerContext";

/**
 * Renders the search site.
 *
 * @returns {*}
 */
function SearchSite() {
  const showMobileDrawerButton = useContext(MobileDrawerContext)
    .showMobileDrawerButtonDispatch;

  useEffect(() => {
    showMobileDrawerButton({ type: "show" });
  }, [showMobileDrawerButton]);

  return (
    <div>
      <SearchApplication />
    </div>
  );
}

export default React.memo(SearchSite);
