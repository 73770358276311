import axios from "axios";

const configJson = require("../config.json");
const APIBASE = new URL(configJson.apiBase);

const SAVE_QUERY_URL = APIBASE + `api/queries/save`;
const GET_RECENT_URL = APIBASE + `api/queries/recent`;
const GET_SAVED_URL = APIBASE + `api/queries/saved`;
const DELETE_QUERY_URL = APIBASE + `api/queries/`;

export async function SaveQuery(query_data) {
  return await axios.put(SAVE_QUERY_URL, JSON.stringify(query_data), {
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + localStorage.getItem("Bearer")
    },
    mode: "CORS"
  });
}

export async function GetRecentQueries() {
  return await axios.get(GET_RECENT_URL, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("Bearer")
    },
    mode: "CORS"
  });
}

export async function GetSavedQueries() {
  return await axios.get(GET_SAVED_URL, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("Bearer")
    },
    mode: "CORS"
  });
}

export async function DeleteQuery(query_id) {
  return await axios.delete(DELETE_QUERY_URL + query_id, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("Bearer")
    },
    mode: "CORS"
  });
}
