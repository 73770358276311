import axios from "axios";

const configJson = require("../config.json");
const APIBASE = new URL(configJson.apiBase);
const APIURL = APIBASE + "api/baskets/";

export default async function basketChangeRequest(basketId, title) {
  return await axios.put(
    APIURL + basketId,
    {
      title: title
    },
    {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("Bearer")
      }
    }
  );
}
