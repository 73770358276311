import React, { useContext, useEffect, useMemo, useState } from "react";
import { SearchProvider } from "@elastic/react-search-ui";
import "@elastic/react-search-ui-views/lib/styles/styles.css";
import WithSearch from "@elastic/react-search-ui/es/WithSearchRenderProps";
import buildState from "../../requests/searchUI/buildState";
import SuggestionRequest from "../../requests/SuggestionRequest";
import SearchRequest from "../../requests/SearchRequest";
import { SaveQuery } from "../../requests/queryHistoryRequests";
import SearchComponent from "./SearchComponent";
import { useParams } from "react-router-dom";
import { SimilarContext } from "../../context/SimilarContext";
import qs from "qs";
import { SimilarBasketsContext } from "../../context/SimilarBasketsContext";
import { LoginContext } from "../../context/LoginContext";
import { SessionContext } from "../../context/SessionContext";
import { requestError } from "../../utility/requestError";
import equal from "deep-equal";
import { LocationContext } from "../../context/LocationContext";
import PropTypes from "prop-types";
import documentRequest from "../../requests/documentRequest";
import axios from "axios";
import basketRequest from "../../requests/basketRequest";
import sharedBasketRequest from "../../requests/sharedBasketRequest";

/**
 * Renders the serch application and handles the search, i.e. sending the search query and processing the results.
 *
 * @param docMode Seach in documents or context.
 * @returns {*}
 */
function SearchApplication({ docMode }) {
  // React Router
  const params = useParams();
  const search = useContext(LocationContext).search;

  const loginContext = useContext(LoginContext);
  const loginState = loginContext.loginState;
  const loginStateDispatcher = loginContext.loginStateDispatcher;
  const sessionContext = useContext(SessionContext);
  const sessionExpiredDispatcher = sessionContext.sessionExpiredDispatcher;

  let m;
  if (docMode) {
    m = docMode;
  } else if (params.mode) {
    m = params.mode;
  } else {
    if (loginState) {
      m = "elabour";
    } else {
      m = "elabour-contexts";
    }
  }
  const [mode, setMode] = useState(m);

  const [loading, setLoading] = useState(false);

  const [initial, setInitial] = useState(true);

  const [filterConfig, setFilterConfig] = useState({});

  const [significantTerms, setSignificantTerms] = useState({
    doc_count: 0,
    buckets: []
  });

  const [aggregations, setAggregations] = useState({});

  const similarContext = useContext(SimilarContext);
  const setSimilarDocuments = similarContext.setSimilarDocuments;
  const similarDocuments = similarContext.similarDocuments;

  const basketsContext = useContext(SimilarBasketsContext);
  const similarBaskets = basketsContext.similarBaskets;
  const setSimilarBaskets = basketsContext.setSimilarBaskets;

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const like = qs.parse(search, { ignoreQueryPrefix: true }).like;

    const setDocuments = async likes => {
      const state = [];
      for (let l of likes) {
        await documentRequest(mode, l, source)
          .then(response => {
            state.push({
              label: response.data._source.title,
              id: l,
              index: mode
            });
          })
          .catch(requestError(sessionExpiredDispatcher, loginStateDispatcher));
      }
      setSimilarDocuments(state);
    };

    if (like) {
      const likes = like.split(",");
      setDocuments(likes);
    }

    return () => {
      source.cancel();
    };
  }, [
    search,
    setSimilarDocuments,
    mode,
    sessionExpiredDispatcher,
    loginStateDispatcher
  ]);

  useEffect(() => {
    const likeBaskets = qs.parse(search, { ignoreQueryPrefix: true })
      .likeBaskets;

    const setBaskets = async basketIds => {
      const state = [];
      const baskets = await basketRequest().catch(
        requestError(sessionExpiredDispatcher, loginStateDispatcher)
      );
      const sharedBaskets = await sharedBasketRequest().catch(
        requestError(sessionExpiredDispatcher, loginStateDispatcher)
      );

      for (let id of basketIds) {
        let basketLabel = id;
        for (let b of baskets.data) {
          if (b._id === id) {
            basketLabel = b.title;
            break;
          }
        }
        for (let b of sharedBaskets.data) {
          if (b._id === id) {
            basketLabel = b.title;
            break;
          }
        }
        if (basketLabel !== id) {
          state.push({ id: id, label: basketLabel });
        }

        setSimilarBaskets(state);
      }
    };

    if (likeBaskets) {
      const baskets = likeBaskets.split(",");
      setBaskets(baskets);
    }
  }, [
    loginStateDispatcher,
    search,
    sessionExpiredDispatcher,
    setSimilarBaskets
  ]);

  const onSearch = async (state, query_mode, searchlike, likeBaskets) => {
    const { searchTerm, current, resultsPerPage, filters } = state;

    setLoading(true);
    setInitial(false);

    let filter_config = undefined;
    if (filters[0]) {
      filter_config = filters[0].values[0];
    }

    if (loginState) {
      SaveQuery({
        recent: true,
        query: {
          term: searchTerm,
          filters: filter_config,
          page: current,
          pageSize: resultsPerPage,
          mode: mode,
          like: searchlike && searchlike.length > 0 ? searchlike : "",
          likeBaskets: likeBaskets && likeBaskets.length > 0 ? likeBaskets : ""
        }
      }).catch(requestError(sessionExpiredDispatcher, loginStateDispatcher));
    }

    const results = await SearchRequest(
      searchTerm,
      (current - 1) * resultsPerPage,
      resultsPerPage,
      query_mode,
      filter_config,
      searchlike,
      likeBaskets
    ).catch(requestError(sessionExpiredDispatcher, loginStateDispatcher));

    setAggregations(results.data.aggregations);

    setLoading(false);

    if (!equal(significantTerms, results.data.aggregations.significantTerms)) {
      setSignificantTerms(results.data.aggregations.significantTerms);
    }

    return buildState(results.data, resultsPerPage, query_mode);
  };

  const onAutocomplete = async ({ searchTerm }) => {
    if (searchTerm.length === 0) {
      return {
        autocompletedSuggestions: { documents: [] },
        autocompletedSuggestionsRequestId: Date.now().toString()
      };
    }

    let suggestions = await SuggestionRequest(searchTerm, "elabour").catch(
      requestError(sessionExpiredDispatcher, loginStateDispatcher)
    );

    let suggestionList = suggestions.data.options.map(function(term) {
      return {
        suggestion: term.text,
        highlight: term.highlighted,
        data: {}
      };
    });

    return {
      autocompletedSuggestions: { documents: suggestionList },
      autocompletedSuggestionsRequestId: Date.now().toString()
    };
  };

  const config_autocompleteQuery = {
    // Customize the query for autocompleteSuggestions
    suggestions: {
      types: {
        // Limit query to only suggest based on "title" field
        documents: { fields: ["title"] }
      },
      // Limit the number of suggestions returned from the server
      size: 4
    }
  };

  const config_search_like = {
    // See https://github.com/elastic/search-ui/blob/master/ADVANCED.md#advanced-configuration
    onSearch: async state => {
      return onSearch(state, "elabour", similarDocuments, similarBaskets);
    },

    onAutocomplete: onAutocomplete,

    autocompleteQuery: config_autocompleteQuery
  };

  const config_search = {
    // See https://github.com/elastic/search-ui/blob/master/ADVANCED.md#advanced-configuration
    onSearch: async state => {
      return onSearch(state, "elabour");
    },

    onAutocomplete: onAutocomplete,

    autocompleteQuery: config_autocompleteQuery
  };

  const config_context_search = {
    // See https://github.com/elastic/search-ui/blob/master/ADVANCED.md#advanced-configuration
    onSearch: async state => {
      return onSearch(state, "elabour-contexts");
    },

    onAutocomplete: onAutocomplete,

    autocompleteQuery: config_autocompleteQuery
  };

  const config_context_search_like = {
    // See https://github.com/elastic/search-ui/blob/master/ADVANCED.md#advanced-configuration
    onSearch: async state => {
      return onSearch(
        state,
        "elabour-contexts",
        similarDocuments,
        similarBaskets
      );
    },

    onAutocomplete: onAutocomplete,

    autocompleteQuery: config_autocompleteQuery
  };

  const innerContent = useMemo(() => {
    return (
      <WithSearch
        mapContextToProps={({
          searchTerm,
          setSearchTerm,
          results,
          setFilter,
          removeFilter,
          filters,
          resultsPerPage,
          setResultsPerPage,
          current,
          reset
        }) => ({
          searchTerm,
          setSearchTerm,
          results,
          setFilter,
          removeFilter,
          filters,
          resultsPerPage,
          setResultsPerPage,
          current,
          reset
        })}
      >
        {({
          searchTerm,
          setSearchTerm,
          results,
          setFilter,
          removeFilter,
          filters,
          resultsPerPage,
          setResultsPerPage,
          current,
          reset
        }) => {
          return (
            <SearchComponent
              searchTerm={searchTerm}
              mode={mode}
              loading={loading}
              initial={initial}
              filterConfig={filterConfig}
              filters={filters}
              significantTerms={significantTerms}
              aggregations={aggregations}
              resultState={{ results, resultsPerPage, current }}
              callbacks={{
                setSearchTerm,
                setFilter,
                setMode,
                setInitial,
                setFilterConfig,
                removeFilter,
                setResultsPerPage,
                reset
              }}
            />
          );
        }}
      </WithSearch>
    );
  }, [aggregations, filterConfig, initial, loading, mode, significantTerms]);

  return (
    <div>
      {((similarDocuments && similarDocuments.length > 0) ||
        (similarBaskets && similarBaskets.length > 0)) &&
        mode === "elabour" && (
          <SearchProvider config={config_search_like}>
            {innerContent}
          </SearchProvider>
        )}

      {!(
        (similarDocuments && similarDocuments.length > 0) ||
        (similarBaskets && similarBaskets.length > 0)
      ) &&
        mode === "elabour" && (
          <SearchProvider config={config_search}>{innerContent}</SearchProvider>
        )}

      {((similarDocuments && similarDocuments.length > 0) ||
        (similarBaskets && similarBaskets.length > 0)) &&
        mode === "elabour-contexts" && (
          <SearchProvider config={config_context_search_like}>
            {innerContent}
          </SearchProvider>
        )}

      {!(
        (similarDocuments && similarDocuments.length > 0) ||
        (similarBaskets && similarBaskets.length > 0)
      ) &&
        mode === "elabour-contexts" && (
          <SearchProvider config={config_context_search}>
            {innerContent}
          </SearchProvider>
        )}
    </div>
  );
}

SearchApplication.propTypes = {
  docMode: PropTypes.string
};

export default React.memo(SearchApplication);
