import AppBar from "@material-ui/core/AppBar";
import { Menu, Toolbar, MenuItem } from "@material-ui/core";
import React, { useContext, useState } from "react";
import Button from "@material-ui/core/Button";
import { strings } from "../localStrings";
import TranslateIcon from "@material-ui/icons/Translate";
import { LanguageContext } from "../context/LanguageContext";
// import useStyles last!
import useStyles from "../useStyles";

/**
 * Renders the language selection menu.
 *
 * @returns {*}
 */
function LanguageMenu() {
  const classes = useStyles();

  const languageContext = useContext(LanguageContext);
  const languageDispatch = languageContext.languageDispatch;

  const [anchorEl, setAnchorEl] = useState(null);

  /**
   * Gets the display name for the provided language code. Must be extended if more languages are added.
   * @param lang The language code
   * @returns {string}
   */
  function getDisplayLanguage(lang) {
    switch (lang) {
      case "en":
        return "English";
      case "de":
        return "Deutsch";
      default:
        return "not implemented";
    }
  }

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  const langs = strings.getAvailableLanguages();

  const languages = langs.map(l => (
    <MenuItem
      onClick={event => {
        languageDispatch({ type: "set", value: l });
        handleClose();
      }}
      key={l}
    >
      {getDisplayLanguage(l)}
    </MenuItem>
  ));

  return (
    <div>
      <Button
        aria-owns={anchorEl ? "language-menu" : undefined}
        aria-haspopup={"true"}
        onClick={handleClick}
        color={"inherit"}
      >
        <TranslateIcon className={classes.leftIcon} />
        {strings.language}
      </Button>
      <Menu
        id="language-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {languages}
      </Menu>
    </div>
  );
}

/**
 * Renders the footer, displayed below all other content.
 *
 * @returns {*}
 * @constructor
 */
function Footer() {
  const classes = useStyles();
  return (
    <AppBar className={classes.footer} position={"static"} elevation={0}>
      <Toolbar>
        <LanguageMenu/>
        <Button
          className={classes.firstButton}
          color={"inherit"}
          href={"http://elabour.de/kontakt/"}
        >
          {strings.contact}
        </Button>
      </Toolbar>
    </AppBar>
  );
}

export default Footer;
