import React from "react";
import {
  Checkbox,
  ListItem,
  ListItemIcon,
  Typography
} from "@material-ui/core";
import ShareIcon from "@material-ui/icons/Share";
import PropTypes from "prop-types";
import useStyles from "../../../useStyles";

/**
 * Renders a single basket for the basket filter.
 *
 * @param entry The basket entry.
 * @param onToggle Callback function to be called when the filter is (de)selected.
 * @param checked Array with the selected filter values.
 * @returns {*}
 * @constructor
 */
export function BasketFilterEntry({ entry, onToggle, checked }) {
  const classes = useStyles();

  return (
    <ListItem dense button onClick={onToggle(entry._id)}>
      <ListItemIcon>
        <Checkbox
          edge="start"
          checked={checked.indexOf(entry._id) !== -1}
          tabIndex={-1}
          disableRipple
        />
      </ListItemIcon>
      {entry.shared && (
        <ListItemIcon className={classes.basketText}>
          <ShareIcon />
        </ListItemIcon>
      )}
      <Typography className={classes.basketText}>{entry.title}</Typography>
    </ListItem>
  );
}

BasketFilterEntry.propTypes = {
  entry: PropTypes.object.isRequired,
  onToggle: PropTypes.func.isRequired,
  checked: PropTypes.arrayOf(PropTypes.string).isRequired
};
