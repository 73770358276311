import React from "react";
import { Button } from "@material-ui/core/";
import { strings } from "../../../localStrings";
import PropTypes from "prop-types";
// import useStyles last!
import useStyles from "../../../useStyles";

function FilterResetButton({ resetFilters }) {
  const classes = useStyles();

  return (
    <div className={classes.buttonContainer}>
      <Button
        className={classes.horizontalCenterButton}
        color={"primary"}
        onClick={resetFilters}
      >
        {strings.resetFilter}
      </Button>
    </div>
  );
}

FilterResetButton.propTypes = {
  resetFilters: PropTypes.func.isRequired
};

export default FilterResetButton;
