import { List, Typography } from "@material-ui/core";
import { strings } from "../../../localStrings";
import { BasketFilterEntry } from "./BasketFilterEntry";
import React from "react";
import equal from "deep-equal";
import PropTypes from "prop-types";
import useStyles from "../../../useStyles";

/**
 * Renders the filter options for the basket filter.
 *
 * @param setFiltetChanged Callback function to be called when the filter is changed.
 * @param baskets Object containing the baskets of the current user.
 * @param checked Array with the selected filter values.
 * @param setChecked Callback function for checked.
 * @returns {*}
 * @constructor
 */
function BasketFilter({ setFilterChanged, baskets, checked, setChecked }) {
  const classes = useStyles();

  const handleToggle = value => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
    setFilterChanged(true);
  };

  if (baskets === undefined || !baskets.length) {
    return (
      <List>
        <Typography align={"center"}>
          {strings.searchDrawer.noBasket}
        </Typography>
      </List>
    );
  } else {
    return (
      <List className={classes.drawerContent}>
        {baskets.map(value => (
          <BasketFilterEntry
            key={value._id}
            entry={value}
            onToggle={handleToggle}
            checked={checked}
          />
        ))}
      </List>
    );
  }
}

BasketFilter.propTypes = {
  setFilterChanged: PropTypes.func.isRequired,
  baskets: PropTypes.array,
  checked: PropTypes.arrayOf(PropTypes.string).isRequired,
  setChecked: PropTypes.func.isRequired
};

function areEqual(prevProps, nextProps) {
  return (
    equal(prevProps.baskets, nextProps.baskets) &&
    equal(prevProps.checked, nextProps.checked)
  );
}

export default React.memo(BasketFilter, areEqual);
