import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from "@material-ui/core";
import { strings } from "../../localStrings";
import PropTypes from "prop-types";

/**
 * Renders the dialog for saving the current query.
 *
 * @param open Visibility of the dialog.
 * @param handleClose Callback function called when the dialog is closed.
 * @param saveQuery Callback function for saving the query.
 * @returns {*}
 */
export default function QueryNameInput({ open, handleClose, saveQuery }) {
  const [text, setText] = useState("");

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        {strings.saveQueryDialog.saveQuery}
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label={strings.saveQueryDialog.queryName}
          value={text}
          onChange={event => {
            setText(event.target.value);
          }}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            saveQuery(text);
            handleClose();
          }}
          color="primary"
        >
          {strings.saveQueryDialog.dialogSubmit}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

QueryNameInput.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  saveQuery: PropTypes.func.isRequired
};
