import { List, ListSubheader, Typography } from "@material-ui/core";
import { FilterEntry } from "./FilterEntry";
import React, { useEffect, useState } from "react";
import { strings } from "../../../localStrings";
import PropTypes from "prop-types";
import useStyles from "../../../useStyles";
import equal from "deep-equal";

/**
 * Renders the filter options for grouped filters.
 *
 * @param filterKey
 * @param setFilterChanged Callback function to be called when the filter is changed.
 * @param facet Object with the possible facet values.
 * @param checked Array with the selected filter values.
 * @param setChecked Callback function for checked.
 * @param docCounts Number of documents for each filter option.
 * @param initial True, if no search was executed.
 * @param parentFacet
 * @param parentChecked
 * @param setParentChecked
 * @returns {*}
 * @constructor
 */
function GroupedFacetFilter({
  filterKey,
  setFilterChanged,
  facet,
  checked,
  setChecked,
  docCounts = {},
  initial,
  parentFacet,
  parentChecked,
  setParentChecked
}) {
  const classes = useStyles();

  let bucketDocCount = {};

  const [parentFacetNames, setParentFacetNames] = useState([]);
  const [facetMap, setFacetMap] = useState({});

  useEffect(() => {
    const names = [];
    for (let bucket of parentFacet.buckets) {
      names.push(bucket.key);
    }
    setParentFacetNames(names);
  }, [parentFacet.buckets]);

  useEffect(() => {
    const facets = {};
    for (let bucket of parentFacet.buckets) {
      facets[bucket.key] = bucket[filterKey];
    }
    setFacetMap(facets);
  }, [filterKey, parentFacet.buckets]);

  if (!initial) {
    for (let bucket of parentFacet.buckets) {
      bucketDocCount[bucket.key] = [];
      for (let i = 0; i < bucket[filterKey].buckets.length; i++) {
        const key = bucket[filterKey].buckets[i].key;
        if (
          docCounts["subCounts"] !== undefined &&
          docCounts["subCounts"][bucket.key] !== undefined &&
          docCounts["subCounts"][bucket.key][filterKey] !== undefined
        ) {
          console.log(docCounts["subCounts"][bucket.key]);
          bucketDocCount[bucket.key][i] =
            docCounts["subCounts"][bucket.key][filterKey][key];
        }
      }
    }
  } else {
    for (let bucket of parentFacet.buckets) {
      bucketDocCount[bucket.key] = [];
      for (let i = 0; i < bucket[filterKey].buckets.length; i++) {
        bucketDocCount[bucket.key][i] = bucket[filterKey].buckets[i].doc_count;
      }
    }
  }

  useEffect(() => {
    if (equal(parentChecked, []) && !equal(checked, [])) {
      setChecked([]);
    }
  }, [checked, parentChecked, setChecked]);

  const handleToggle = (value, parent) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    const parentCurrentIndex = parentChecked.indexOf(parent);
    const newParentChecked = [...parentChecked];

    if (currentIndex === -1) {
      newChecked.push(value);
      if (parentCurrentIndex === -1) {
        newParentChecked.push(parent);
      }
    } else if (parentCurrentIndex === -1) {
      newParentChecked.push(parent);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
    setParentChecked(newParentChecked);
    setFilterChanged(true);
  };

  const getChecked = parentName => {
    if (parentChecked.includes(parentName)) {
      return checked;
    } else {
      return [];
    }
  };

  if (
    parentFacet.buckets.length === 0 ||
    (parentFacet.buckets.length === 1 &&
      (parentFacet.buckets[0].key === "<OTHER>" ||
        parentFacet.buckets[0] === ""))
  ) {
    return (
      <Typography className={classes.centeredText}>
        {strings.searchDrawer.noFilter}
      </Typography>
    );
  } else {
    return (
      <List className={classes.drawerContent} subheader={<li />}>
        {parentFacetNames.map(name => {
          return (
            <li key={"section-" + name}>
              <ul>
                <ListSubheader>{name}</ListSubheader>
                {facetMap[name].buckets.map((value, index) => (
                  <FilterEntry
                    key={value.key}
                    onToggle={key => handleToggle(key, name)}
                    docCount={bucketDocCount[name][index] || 0}
                    entry={value}
                    checked={getChecked(name)}
                  />
                ))}
              </ul>
            </li>
          );
        })}
      </List>
    );
  }
}

GroupedFacetFilter.propTypes = {
  setFilterChanged: PropTypes.func.isRequired,
  facet: PropTypes.object.isRequired,
  checked: PropTypes.arrayOf(PropTypes.string).isRequired,
  setChecked: PropTypes.func.isRequired,
  docCounts: PropTypes.object.isRequired,
  initial: PropTypes.bool
};

export default React.memo(GroupedFacetFilter);
