import { useEffect } from "react";

function useSignificantTerms(content, setSignificantTerms) {
  useEffect(() => {
    if (content !== undefined && content.aggregations !== undefined) {
      const terms = content.aggregations.significantTerms.buckets.map(item => {
        return item.key;
      });
      const termsWithoutEmptyElements = terms.filter(e => {
        return e != null;
      });
      setSignificantTerms(termsWithoutEmptyElements);
    }
  }, [content, setSignificantTerms]);
}

export default useSignificantTerms;
