import { AppBar, Hidden, Toolbar, IconButton, Button } from "@material-ui/core";
import HomeIcon from "@material-ui/icons/Home";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import BasketIcon from "@material-ui/icons/ShoppingBasket";
import ShareIcon from "@material-ui/icons/Share";
import { strings } from "../localStrings";
import LoginButton from "./LoginButton";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { LoginContext } from "../context/LoginContext";
import { MobileDrawerContext } from "../context/MobileDrawerContext";
import PropTypes from "prop-types";
// import useStyles last!
import useStyles from "../useStyles";

// Link to Homepage
const HomeLink = React.forwardRef((props, ref) => (
  <Link innerRef={ref} to="/" {...props} />
));

// Link to BasketSite
const BasketLink = React.forwardRef((props, ref) => (
  <Link innerRef={ref} to="/baskets" {...props} />
));

// Link to SharedBasketSite
const SharedBasketLink = React.forwardRef((props, ref) => (
  <Link innerRef={ref} to="/sharedbaskets" {...props} />
));

// Link to SearchQueriesSite
const SearchQueriesLink = React.forwardRef((props, ref) => (
  <Link innerRef={ref} to="/queries" {...props} />
));

/**
 * Renders the main navigation bar.
 *
 * @param language The current language. Only needed so the component rerenders on language change.
 * @returns {*}
 */
function MainAppBar({ language }) {
  const classes = useStyles();

  const mobileDrawerContext = useContext(MobileDrawerContext);
  const showMobileDrawerButton = mobileDrawerContext.showMobileDrawerButton;
  const mobileDrawerDispatch = mobileDrawerContext.mobileDrawerDispatch;

  const loginState = useContext(LoginContext).loginState;

  return (
    <div>
      <AppBar position={"static"}>
        <Toolbar>
          <Hidden mdUp>
            {showMobileDrawerButton && (
              <IconButton
                edge={"start"}
                color={"inherit"}
                className={classes.menuButton}
                onClick={() => mobileDrawerDispatch({ type: "show" })}
              >
                <MenuIcon />
              </IconButton>
            )}
          </Hidden>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <Button
              className={classes.button}
              color={"inherit"}
              component={HomeLink}
            >
              <HomeIcon className={classes.leftIcon} />
              {strings.homepage}
            </Button>
            {loginState && (
              <Button
                className={classes.button}
                color={"inherit"}
                component={BasketLink}
              >
                <BasketIcon className={classes.leftIcon} />
                {strings.baskets}
              </Button>
            )}
            {loginState && (
              <Button
                className={classes.button}
                color={"inherit"}
                component={SharedBasketLink}
              >
                <ShareIcon className={classes.leftIcon} />
                {strings.sharedBaskets}
              </Button>
            )}
            {loginState && (
              <Button
                className={classes.button}
                color={"inherit"}
                component={SearchQueriesLink}
              >
                <SearchIcon className={classes.leftIcon} />
                {strings.searchQueries}
              </Button>
            )}
            <LoginButton />
          </div>
          <div className={classes.sectionMobile}>
            <IconButton color={"inherit"} component={HomeLink}>
              <HomeIcon />
            </IconButton>
            {loginState && (
              <IconButton color={"inherit"} component={BasketLink}>
                <BasketIcon />
              </IconButton>
            )}
            {loginState && (
              <IconButton color={"inherit"} component={SharedBasketLink}>
                <ShareIcon />
              </IconButton>
            )}
            {loginState && (
              <IconButton color={"inherit"} component={SearchQueriesLink}>
                <SearchIcon />
              </IconButton>
            )}
            <LoginButton />
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}

MainAppBar.propTypes = {
  language: PropTypes.string
};

export default MainAppBar;
