import React, { useContext, useEffect, useState } from "react";
import {
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Link,
  Button,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Dialog,
  Typography,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Snackbar
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { strings } from "../../localStrings";
import basketAddDocumentRequest from "../../requests/basketAddDocumentRequest";
import basketCreateRequest from "../../requests/basketCreateRequest";
import SnackbarContentWrapper from "../SnackbarContentWrapper";
import { LoginContext } from "../../context/LoginContext";
import { SessionContext } from "../../context/SessionContext";
import { requestError } from "../../utility/requestError";
import equal from "deep-equal";
import { LanguageContext } from "../../context/LanguageContext";
import { queryCache, useMutation } from "react-query";
import PropTypes from "prop-types";
// Import useStyles last!
import useStyles from "../../useStyles";

/**
 * Custom ResultView for Elastic Search-UI.
 *
 * @param result The results.
 * @param baskets The baskets of the current user.
 * @param addSimilarDocument
 * @param mode
 * @param isQueryEmpty
 * @param selection
 * @param selectionDispatch
 * @returns {*}
 */
function ResultView({
  result,
  baskets,
  addSimilarDocument,
  mode,
  isQueryEmpty,
  selection,
  selectionDispatch
}) {
  const classes = useStyles();

  const language = useContext(LanguageContext); // Rerender on language change

  const loginContext = useContext(LoginContext);
  const loginState = loginContext.loginState;
  const loginStateDispatcher = loginContext.loginStateDispatcher;
  const sessionContext = useContext(SessionContext);
  const sessionExpiredDispatcher = sessionContext.sessionExpiredDispatcher;

  const [basketDialogOpen, setBasketDialogOpen] = useState(false);
  const [newBasketDialogOpen, setNewBasketDialogOpen] = useState(false);
  const [newBasketName, setNewBasketName] = useState("");
  const [emptyNameOpen, setEmptyNameOpen] = useState(false);
  const [selected, setSelected] = useState(selection.includes(result.id.raw));

  useEffect(() => {
    if (selected) {
      selectionDispatch({ type: "add", value: result.id.raw });
    } else selectionDispatch({ type: "remove", value: result.id.raw });
  }, [result.id.raw, selected, selectionDispatch]);

  const handleBasketDialogOpen = () => {
    setBasketDialogOpen(true);
  };

  const handleCloseBasketDialog = () => {
    setBasketDialogOpen(false);
  };

  const handleNewBasketDialogOpen = () => {
    setNewBasketDialogOpen(true);
  };

  const handleCloseNewBasketDialog = () => {
    setNewBasketDialogOpen(false);
  };

  const handleSnackbarClose = () => {
    setEmptyNameOpen(false);
  };

  const handleListItemClick = value => {
    handleCloseBasketDialog();
    let resources = {};
    resources[mode] = [result.id.raw];

    if (value !== 0) {
      if (loginState) {
        basketAddDocumentRequest(value, resources).catch(
          requestError(sessionExpiredDispatcher, loginStateDispatcher)
        );
      }
    } else {
      handleNewBasketDialogOpen();
    }
  };

  const handleSimilarSearch = (title, id) => {
    addSimilarDocument(title, id, mode);
  };

  const [createBasketMutation] = useMutation(
    ({ newBasketName, resources }) =>
      basketCreateRequest(newBasketName, resources),
    {
      onSuccess: () => {
        queryCache.invalidateQueries("baskets");
      }
    }
  );

  const createBasket = () => {
    handleCloseNewBasketDialog();
    if (newBasketName === "") {
      setEmptyNameOpen(true);
    } else {
      let resources = {};
      resources[mode] = [result.id.raw];

      if (loginState) {
        createBasketMutation({ newBasketName, resources }).catch(
          requestError(sessionExpiredDispatcher, loginStateDispatcher)
        );
      }
    }
  };

  const handleSelection = event => {
    setSelected(event.target.checked);
  };

  function hasSnippet() {
    return (
      isQueryEmpty ||
      result.content === undefined ||
      result.content.snippet === undefined
    );
  }

  return (
    <Card className={classes.resultCard} elevation={4}>
      <CardContent>
        <Typography color="textSecondary" gutterBottom>
          {strings.documentDetails.study}: {result["study-title"].raw}
        </Typography>
        <Link
          component={RouterLink}
          to={
            hasSnippet()
              ? result.nps_link.raw
              : result.nps_link.raw +
                "?search=" +
                result.content.snippet.replace(/<\/?strong>/g, "")
          }
          color={"inherit"}
          variant={"h5"}
        >
          {result.title.raw}
        </Link>
        <Typography>
          <span className={classes.bold}>
            {strings.documentDetails.docClass}:{" "}
          </span>
          {result["document-class"].raw}
        </Typography>
        <Typography>
          <span className={classes.bold}>
            {strings.documentDetails.explorationType}:{" "}
          </span>
          {result["exploration-type"].raw}
        </Typography>
        <Typography>
          <span className={classes.bold}>{strings.documentDetails.case}: </span>
          {result.case.raw}
        </Typography>
        <Typography>
          <span className={classes.bold}>{strings.documentDetails.wave}: </span>
          {result.wave.raw}
        </Typography>
        <Typography>
          <span className={classes.bold}>
            {strings.documentDetails.collectionTimespan}:{" "}
          </span>
          {result["collection-timespan"].raw.gte} &mdash;{" "}
          {result["collection-timespan"].raw.lte}
        </Typography>
        <Typography>
          <span className={classes.bold}>
            {strings.documentDetails.researchTimespan}:{" "}
          </span>
          {result["research-timespan"].raw.gte} &mdash;{" "}
          {result["research-timespan"].raw.lte}
        </Typography>
        <Typography
          className={classes.snippet}
          variant={"body2"}
          dangerouslySetInnerHTML={{ __html: result.content.snippet }}
        />
      </CardContent>
      <CardActions disableSpacing>
        {loginState && (
          <Button
            className={classes.button}
            size={"small"}
            onClick={handleBasketDialogOpen}
          >
            {strings.documentDetails.addToBasket}
          </Button>
        )}
        <Button
          className={classes.button}
          size={"small"}
          onClick={() => handleSimilarSearch(result.title.raw, result.id.raw)}
        >
          {strings.similarDocuments}
        </Button>
        <Checkbox
          className={classes.firstButton}
          color={"primary"}
          checked={selected}
          onChange={handleSelection}
        />
      </CardActions>
      <Dialog open={basketDialogOpen} onClose={handleCloseBasketDialog}>
        <DialogTitle id={"basket-dialog-title"}>
          {strings.documentDetails.selectBasket}
        </DialogTitle>
        <List>
          {baskets.map(
            basket =>
              !basket.shared && (
                <ListItem
                  button
                  onClick={() => handleListItemClick(basket._id)}
                  key={basket._id}
                >
                  <ListItemText primary={basket.title} />
                </ListItem>
              )
          )}
          <ListItem button onClick={() => handleListItemClick(0)} key={0}>
            <ListItemText primary={strings.newBasket} />
          </ListItem>
        </List>
      </Dialog>
      <Dialog
        open={newBasketDialogOpen}
        onClose={handleCloseNewBasketDialog}
        fullWidth
      >
        <DialogTitle id="form-dialog-title">
          {strings.userSite.basketDialogTitle}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {strings.userSite.basketDialogText}
          </DialogContentText>
          <TextField
            autoFocus
            margin={"dense"}
            id={"basketTitle"}
            fullWidth
            variant={"outlined"}
            label={strings.userSite.basketName}
            onChange={event => setNewBasketName(event.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseNewBasketDialog} color="primary">
            {strings.cancel}
          </Button>
          <Button onClick={createBasket} color="primary">
            {strings.create}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={emptyNameOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <SnackbarContentWrapper
          onClose={handleSnackbarClose}
          variant={"error"}
          message={strings.userSite.basketNameEmptyText}
        />
      </Snackbar>
    </Card>
  );
}

ResultView.propTypes = {
  result: PropTypes.object.isRequired,
  baskets: PropTypes.arrayOf(PropTypes.object).isRequired,
  addSimilarDocument: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
  isQueryEmpty: PropTypes.bool.isRequired,
  selection: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectionDispatch: PropTypes.func.isRequired
};

function areEqual(prevProps, nextProps) {
  return equal(prevProps, nextProps);
}

export default React.memo(ResultView, areEqual);
