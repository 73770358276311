import {
  Collapse,
  ListItem,
  ListItemIcon,
  ListItemText
} from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import useStyles from "../../useStyles";

/**
 * Renders a single entry of the drawer with the options for one filter category.
 *
 * @param text The title of the drawer entry.
 * @param body The body of the drawer entry.
 * @param checked
 * @returns {*}
 */
function DrawerListEntry({ text, body, checked }) {
  const classes = useStyles();
  const [open, setOpen] = useState(checked.length !== 0);

  // Workaround for YearFilter
  useEffect(() => {
    setOpen(checked.length !== 0)
  }, [checked])

  function handleClick() {
    setOpen(state => !state);
  }

  return (
    <div>
      <ListItem button onClick={handleClick}>
        <ListItemIcon>
          <FilterListIcon />
        </ListItemIcon>
        <ListItemText primary={text} />
        {open ? <ExpandLess /> : <ExpandMoreIcon />}
      </ListItem>
      <Collapse
        className={classes.backgroundLighter}
        in={open}
        timeout="auto"
        unmountOnExit
      >
        {body}
      </Collapse>
    </div>
  );
}

DrawerListEntry.propTypes = {
  text: PropTypes.string.isRequired,
  body: PropTypes.element.isRequired,
  checked: PropTypes.array.isRequired
};

DrawerListEntry.whyDidYouRender = true;

export default DrawerListEntry;
