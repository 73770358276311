import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import { LoginContext } from "../context/LoginContext";
import { Redirect, Route, useLocation } from "react-router-dom";
import equal from "deep-equal";
import { LocationContext } from "../context/LocationContext";
import SearchSite from "../sites/SearchSite";
import DocumentSite from "../sites/DocumentSite";
import LoginSite from "../sites/LoginSite";
import SearchQueriesSite from "../sites/SearchQueriesSite";
import BasketSite from "../sites/BasketSite";
import SharedBasketSite from "../sites/SharedBasketSite";

/**
 * Renders the main content (search, document details, login, saved searches, baskets).
 *
 * @returns {*}
 */
export const MainContent = React.memo(function MainContent() {
  const loginState = useContext(LoginContext).loginState;
  const location = useLocation();
  const reload = useCallback(() => window.location.reload(), []);
  const [pathname, setPathname] = useState(location.pathname);
  const [search, setSearch] = useState(location.search);
  const [locationContextState, setLocationContextState] = useState({
    pathname: pathname,
    search: search
  });
  useEffect(() => {
    if (location.pathname !== pathname) {
      setPathname(location.pathname);
    }
  }, [location.pathname, pathname]);

  useEffect(() => {
    if (location.search !== search) {
      setSearch(location.search);
    }
  }, [location.search, search]);

  useEffect(() => {
    if (
      !equal(pathname, locationContextState.pathname) ||
      !equal(search, locationContextState.search)
    ) {
      setLocationContextState({
        pathname: pathname,
        search: search
      });
    }
  }, [locationContextState, pathname, search]);

  return useMemo(() => {
    if (!loginState && pathname !== "/login") {
      return <Redirect to={"/login"} />;
    }
    return (
      <>
        <LocationContext.Provider value={locationContextState}>
          <Route exact path="/">
            <Redirect to={"/s/elabour"} />
          </Route>
          <Route path="/s/:mode" render={() => <SearchSite />} />
          <Route path="/document/:docId/" render={() => <DocumentSite />} />
          <Route path="/context/:docId/" render={() => <DocumentSite />} />
          <Route path="/login" render={() => <LoginSite />} />
          <Route path="/queries" render={() => <SearchQueriesSite />} />
          <Route path="/baskets" render={() => <BasketSite />} />
          <Route path="/sharedbaskets" render={() => <SharedBasketSite />} />
          <Route path="`/pdfjs-2.4.456-dist/web/viewer.html" onEnter={reload} />
        </LocationContext.Provider>
      </>
    );
  }, [locationContextState, loginState, pathname, reload]);
});
