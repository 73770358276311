import axios from "axios";
import { requestError } from "../utility/requestError";

const configJson = require("../config.json");
const APIBASE = new URL(configJson.apiBase);

export default function overViewSearchRequest(
  search_term,
  mode,
  filters,
  sessionExpiredDispatcher,
  loginStateDispatcher
) {
  let filter;
  if (!filters || filters.length === 0) {
    filter = {
      year: {
        to: "",
        from: ""
      },
      facets: {},
      baskets: []
    };
  } else {
    filter = fixFacets(filters)[0].values[0];
  }

  let results;

  return async () => {
    await axios
      .post(
        APIBASE + "api/search/" + mode + "/query",
        {
          term: search_term,
          pageSize: 10,
          offset: 0,
          overview: true,
          filters: {
            baskets: filter.baskets ? filter.baskets : [],
            collectionDate: {
              from: filter.year && filter.year.from ? filter.year.from : "",
              to: filter.year && filter.year.to ? filter.year.to : ""
            },
            facets: filter.facets ? filter.facets : {}
          }
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("Bearer")
          }
        }
      )
      .then(response => {
        results = response;
      })
      .catch(requestError(sessionExpiredDispatcher, loginStateDispatcher));
    return results.data;
  };
}

/**
 * Function fixes wrong format caused by search-ui's URL parsing
 * @param filter The parsed filter object
 * @returns The corrected parsed filter object
 */
function fixFacets(filter) {
  for (let key in filter.facets) {
    if (filter.facets[key] !== undefined && !isValueArray(filter.facets[key])) {
      let array = [];

      for (let subkey in filter.facets[key]) {
        array.push(filter.facets[key][subkey]);
      }

      filter.facets[key] = array;
    }
  }

  return filter;
}

function isValueArray(value) {
  //For old browsers
  if (typeof Array.isArray === "undefined") {
    return Object.prototype.toString.call(value) === "[object Array]";
  } else {
    return Array.isArray(value);
  }
}
