import { createContext } from "react";

export const SessionContext = createContext({
  loginSuccess: false,
  loginSuccessDispatcher: () => {
  },
  notLoggedIn: false,
  notLoggedInDispatcher: () => {
  },
  sessionExpiredDispatcher: () => {
  },
  notFound: false,
  notFoundDispatcher: () => {
  }
});
