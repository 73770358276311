import axios from "axios";

const configJson = require("../config.json");
const APIBASE = new URL(configJson.apiBase);

export default async function SuggestionRequest(search_term, mode) {
  let payload = {
    term: search_term
  };

  return await axios.post(
    APIBASE + "api/search/" + mode + "/suggestions",
    JSON.stringify(payload),
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("Bearer")
      },
      mode: "CORS"
    }
  );
}
