import React, { useState, useContext } from "react";
import {
  CardContent,
  CardActions,
  Button,
  Card,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Typography,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Snackbar
} from "@material-ui/core";
import { strings } from "../../localStrings";
import basketAddDocumentRequest from "../../requests/basketAddDocumentRequest";
import { useHistory } from "react-router-dom";
import { SimilarContext } from "../../context/SimilarContext";
import { LoginContext } from "../../context/LoginContext";
import basketCreateRequest from "../../requests/basketCreateRequest";
import SnackbarContentWrapper from "../SnackbarContentWrapper";
import equal from "deep-equal";
import { SessionContext } from "../../context/SessionContext";
import { requestError } from "../../utility/requestError";
import { queryCache, useMutation } from "react-query";
import PropTypes from "prop-types";
// Import useStyles last!
import useStyles from "../../useStyles";

/**
 * Renders the document details.
 *
 * @param document The document details object.
 * @param mode Document or context
 * @param baskets The baskets of the current user so the document can be added.
 * @returns {*}
 */
function DocumentDetails({ document, mode, baskets }) {
  const classes = useStyles();
  const history = useHistory();

  const similarContext = useContext(SimilarContext);
  const setSimilarDocuments = similarContext.setSimilarDocuments;

  const loginContext = useContext(LoginContext);
  const loginState = loginContext.loginState;
  const loginStateDispatcher = loginContext.loginStateDispatcher;
  const sessionContext = useContext(SessionContext);
  const sessionExpiredDispatcher = sessionContext.sessionExpiredDispatcher;

  const [basketDialogOpen, setBasketDialogOpen] = useState(false);
  const [redirectToSearch, setRedirectToSearch] = useState(false);
  const [newBasketDialogOpen, setNewBasketDialogOpen] = useState(false);
  const [newBasketName, setNewBasketName] = useState("");
  const [emptyNameOpen, setEmptyNameOpen] = useState(false);

  const handleBasketDialogOpen = () => {
    setBasketDialogOpen(true);
  };

  const handleCloseBasketDialog = () => {
    setBasketDialogOpen(false);
  };

  const handleNewBasketDialogOpen = () => {
    setNewBasketDialogOpen(true);
  };

  const handleCloseNewBasketDialog = () => {
    setNewBasketDialogOpen(false);
  };

  const handleSnackbarClose = () => {
    setEmptyNameOpen(false);
  };

  const handleListItemClick = value => {
    handleCloseBasketDialog();
    let resources = {};
    resources[mode] = [document.id];

    if (value !== 0) {
      if (loginState) {
        basketAddDocumentRequest(value, resources).catch(
          requestError(sessionExpiredDispatcher, loginStateDispatcher)
        );
      }
    } else {
      handleNewBasketDialogOpen();
    }
  };

  const [createBasketMutation] = useMutation(
    ({ newBasketName, resources }) =>
      basketCreateRequest(newBasketName, resources),
    {
      onSuccess: () => {
        queryCache.invalidateQueries("baskets");
      }
    }
  );

  const createBasket = () => {
    handleCloseNewBasketDialog();
    if (newBasketName === "") {
      setEmptyNameOpen(true);
    } else {
      let resources = {};
      resources[mode] = [document.id];

      if (loginState) {
        createBasketMutation({ newBasketName, resources }).catch(
          requestError(sessionExpiredDispatcher, loginStateDispatcher)
        );
      }
    }
  };

  const goBack = () => {
    history.goBack();
  };

  const handleSimilarSearch = () => {
    setSimilarDocuments([
      { label: document["title"], id: document["id"], index: mode }
    ]);
    setRedirectToSearch(true);
  };

  if (redirectToSearch) {
    setRedirectToSearch(false);
    history.push("/");
  }

  const SnackbarAnchorOrigin = { vertical: "top", horizontal: "center" };
  return (
    <Card>
      <CardContent>
        <Button
          size={"small"}
          className={classes.button}
          onClick={goBack}
          color={"primary"}
        >
          {strings.documentDetails.back}
        </Button>
        <Typography color="textSecondary" gutterBottom>
          {strings.documentDetails.study}:{" "}
          {document["study-title"] + " (" + document["study-title-alt"] + ")"}
        </Typography>
        <Typography variant={"h4"} component={"h1"}>
          {document["title"]}
        </Typography>

        <Typography>
          <span className={classes.bold}>
            {strings.documentDetails.docClass}:{" "}
          </span>
          {document["document-class"]}
        </Typography>
        <Typography>
          <span className={classes.bold}>
            {strings.documentDetails.explorationType}:{" "}
          </span>
          {document["exploration-type"]}
        </Typography>
        <Typography>
          <span className={classes.bold}>{strings.documentDetails.case}: </span>
          {document["case"]}
        </Typography>
        <Typography>
          <span className={classes.bold}>{strings.documentDetails.wave}: </span>
          {document["wave"]}
        </Typography>
        <Typography>
          <span className={classes.bold}>
            {strings.documentDetails.collectionTimespan}:{" "}
          </span>
          {document["collection-timespan"] !== undefined &&
            document["collection-timespan"] !== "" && (
              <span>
                {document["collection-timespan"]["gte"]} &mdash;{" "}
                {document["collection-timespan"]["lte"]}
              </span>
            )}
        </Typography>
        <Typography>
          <span className={classes.bold}>
            {strings.documentDetails.researchTimespan}:{" "}
          </span>
          {document["research-timespan"] !== undefined &&
            document["research-timespan"] !== "" && (
              <span>
                {document["research-timespan"]["gte"]} &mdash;{" "}
                {document["research-timespan"]["lte"]}
              </span>
            )}
        </Typography>
      </CardContent>
      <CardActions>
        {loginState && (
          <Button
            size="small"
            className={classes.button}
            onClick={handleBasketDialogOpen}
          >
            {strings.documentDetails.addToBasket}
          </Button>
        )}
        <Dialog open={basketDialogOpen} onClose={handleCloseBasketDialog}>
          <DialogTitle id={"basket-dialog-title"}>
            {strings.documentDetails.selectBasket}
          </DialogTitle>
          <List>
            {baskets.map(basket => (
              <ListItem
                button
                onClick={() => handleListItemClick(basket._id)}
                key={basket._id}
              >
                <ListItemText primary={basket.title} />
              </ListItem>
            ))}
            <ListItem button onClick={() => handleListItemClick(0)} key={0}>
              <ListItemText primary={strings.newBasket} />
            </ListItem>
          </List>
        </Dialog>
        <Dialog
          open={newBasketDialogOpen}
          onClose={handleCloseNewBasketDialog}
          fullWidth
        >
          <DialogTitle id="form-dialog-title">
            {strings.userSite.basketDialogTitle}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {strings.userSite.basketDialogText}
            </DialogContentText>
            <TextField
              autoFocus
              margin={"dense"}
              id={"basketTitle"}
              fullWidth
              variant={"outlined"}
              label={strings.userSite.basketName}
              onChange={event => setNewBasketName(event.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseNewBasketDialog} color="primary">
              {strings.cancel}
            </Button>
            <Button onClick={createBasket} color="primary">
              {strings.create}
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          anchorOrigin={SnackbarAnchorOrigin}
          open={emptyNameOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <SnackbarContentWrapper
            onClose={handleSnackbarClose}
            variant={"error"}
            message={strings.userSite.basketNameEmptyText}
          />
        </Snackbar>
        <Button
          size={"small"}
          className={classes.button}
          onClick={handleSimilarSearch}
        >
          {strings.documentDetails.similarSearch}
        </Button>
      </CardActions>
    </Card>
  );
}

DocumentDetails.propTypes = {
  document: PropTypes.object.isRequired,
  mode: PropTypes.string.isRequired,
  baskets: PropTypes.arrayOf(PropTypes.object).isRequired
};

function areEqual(prevProps, nextProps) {
  return equal(prevProps, nextProps);
}

export default React.memo(DocumentDetails, areEqual);
