import React from "react";
import ReactWordcloud from "react-wordcloud";
import equal from "deep-equal";
import PropTypes from "prop-types";

/**
 * Renders a wordcloud.
 *
 * @param termVectors Elasticsearch termvector object.
 * @param significantTerms Elasticsearch significantTerms object.
 * @param append_searchTerm Callback function to append a term to the search query.
 * @returns {*}
 * @constructor
 */
function Wordcloud({ termVectors, significantTerms, append_searchTerm }) {
  const onWordClick = word => {
    if (append_searchTerm) {
      append_searchTerm(word.text);
    }
  };

  const data = [];

  if (significantTerms) {
    for (let index in significantTerms.buckets) {
      data.push({
        text: significantTerms.buckets[index].key,
        value: significantTerms.buckets[index].score
      });
    }
  }

  if (termVectors) {
    for (let term in termVectors.terms) {
      data.push({ text: term, value: termVectors.terms[term].score });
    }
  }

  return (
    <div>
      <ReactWordcloud
        words={data}
        options={{
          colors: [
            "#1f77b4",
            "#ff7f0e",
            "#2ca02c",
            "#d62728",
            "#9467bd",
            "#8c564b"
          ],
          enableTooltip: false,
          fontFamily: "impact",
          fontSizes: [14, 24],
          rotations: 0,
          rotationAngles: [0, -90],
          padding: 2,
          spiral: "archimedean"
        }}
        callbacks={{
          onWordClick: onWordClick
        }}
      />
    </div>
  );
}

Wordcloud.propTypes = {
  termVectors: PropTypes.object,
  significantTerms: PropTypes.object,
  append_searchTerm: PropTypes.func
};

function areEqual(prevProps, nextProps) {
  return (
    equal(prevProps.termVectors, nextProps.termVectors) &&
    equal(prevProps.significantTerms, nextProps.significantTerms)
  );
}

export default React.memo(Wordcloud, areEqual);
