import React, { useState } from "react";
import { Grid, List, ListItem, Slider } from "@material-ui/core";
import equal from "deep-equal";
import PropTypes from "prop-types";
import useStyles from "../../../useStyles";

/**
 * Renders filter options for the year filter.
 *
 * @param setFilterChanged Callback function to be called when the filter changed.
 * @param yearFilterFrom The currently set lower value of the year filter.
 * @param yearFilterTo The currently set upper value of the year filter.
 * @param setYearFilterFrom Callback function for yearFilterFrom.
 * @param setYearFilterTo Callback function for yearFilterTo.
 * @param maxYear The upper limit of the year filter.
 * @param minYear The lower limit of the year filter.
 * @param YEAR_MIN_VALUE The minimum value used for the slider of the year filter.
 * @returns {*}
 * @constructor
 */
function YearFilter({
  setFilterChanged,
  yearFilterFrom,
  yearFilterTo,
  setYearFilterFrom,
  setYearFilterTo,
  maxYear,
  minYear,
  YEAR_MIN_VALUE
}) {
  const classes = useStyles();
  const [displayValue, setDisplayValue] = useState([
    Math.max(minYear, Number(yearFilterFrom ? yearFilterFrom : minYear)),
    Math.min(maxYear, Number(yearFilterTo ? yearFilterTo : maxYear))
  ]);

  const handleChange = (event, newValue) => {
    setDisplayValue(newValue);
  };

  return (
    <List>
      <ListItem>
        <Grid container>
          <Slider
            value={displayValue}
            getAriaValueText={value => {
              return `From ${value[0]} to  ${value[1]}`;
            }}
            aria-labelledby="range-slider"
            valueLabelDisplay="on"
            min={YEAR_MIN_VALUE}
            max={new Date().getFullYear()}
            onChange={handleChange}
            onChangeCommitted={(event, value) => {
              setYearFilterFrom(value[0]);
              setYearFilterTo(value[1]);
              setFilterChanged(true);
            }}
            className={classes.YearFilter}
            step={1}
          />
        </Grid>
      </ListItem>
    </List>
  );
}

YearFilter.propTypes = {
  setFilterChanged: PropTypes.func.isRequired,
  yearFilterFrom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  yearFilterTo: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setYearFilterFrom: PropTypes.func.isRequired,
  setYearFilterTo: PropTypes.func.isRequired,
  maxYear: PropTypes.number.isRequired,
  minYear: PropTypes.number.isRequired,
  YEAR_MIN_VALUE: PropTypes.number.isRequired
};

function areEqual(prevProps, nextProps) {
  return (
    equal(prevProps.yearFilterFrom, nextProps.yearFilterFrom) &&
    equal(prevProps.yearFilterTo, nextProps.yearFilterTo) &&
    equal(prevProps.maxYear, nextProps.maxYear) &&
    equal(prevProps.minYear, nextProps.minYear) &&
    equal(prevProps.YEAR_MIN_VALUE, nextProps.YEAR_MIN_VALUE)
  );
}

export default React.memo(YearFilter, areEqual);
