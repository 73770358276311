import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField
} from "@material-ui/core";
import { strings } from "../../localStrings";
import PropTypes from "prop-types";

export function CreateBasketDialog({ open, onClose, onChange, onCreate }) {
  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle id="form-dialog-title">
        {strings.userSite.basketDialogTitle}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {strings.userSite.basketDialogText}
        </DialogContentText>
        <TextField
          autoFocus
          margin={"dense"}
          id={"basketTitle"}
          fullWidth
          variant={"outlined"}
          label={strings.userSite.basketName}
          onChange={onChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {strings.cancel}
        </Button>
        <Button onClick={onCreate} color="primary">
          {strings.create}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

CreateBasketDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired
};
