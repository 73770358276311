import axios from "axios";
import { requestError } from "../utility/requestError";

const configJson = require("../config.json");
const APIBASE = new URL(configJson.apiBase);
const APIURL = APIBASE + "api/search/elabour/query";
const CONTEXTURL = APIBASE + "api/search/elabour-contexts/query";

export default function basketContentSearchRequest(
  basketId,
  sessionExpiredDispatcher,
  loginStateDispatcher
) {
  return async () => {
    let content;
    await axios
      .post(
        APIURL,
        {
          pageSize: 10,
          offset: 0,
          overview: true,
          term: "",
          filters: {
            baskets: [basketId]
          }
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("Bearer")
          }
        }
      )
      .then(function(response) {
        content = response.data;
      })
      .catch(requestError(sessionExpiredDispatcher, loginStateDispatcher));

    await axios
      .post(
        CONTEXTURL,
        {
          pageSize: 10,
          offset: 0,
          overview: true,
          term: "",
          filters: {
            baskets: [basketId]
          }
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("Bearer")
          }
        }
      )
      .then(function(response) {
        content.hits.hits.push(...response.data.hits.hits);
        content.aggregations.significantTerms.buckets.push(
          response.data.aggregations.significantTerms.buckets
        );
      })
      .catch(requestError(sessionExpiredDispatcher, loginStateDispatcher));

    return content;
  };
}
