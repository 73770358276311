import axios from "axios";

const configJson = require("../config.json");
const APIBASE = new URL(configJson.apiBase);
const APIURL = APIBASE + "api/baskets/";

export default async function basketDeleteRequest(basketId) {
  return await axios.delete(APIURL + basketId, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("Bearer")
    }
  });
}
