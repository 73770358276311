import red from "@material-ui/core/colors/red";
import { createMuiTheme } from "@material-ui/core/styles";

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#E5221D",
      dark: "#c7201b",
      light: "#ff221d"
    },
    secondary: {
      main: "#9e9e9e",
      light: "#e8e8e8",
      lighter: "#f0f0f0"
    },
    error: {
      main: red.A400
    },
    background: {
      default: "#fff"
    }
  }
});

export default theme;
