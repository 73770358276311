import React from "react";
import { Typography } from "@material-ui/core";
import { strings } from "../../localStrings";
import PropTypes from "prop-types";

/**
 * Displays the position of the curently displayed search results, i.e. 11 - 20 of 30.
 *
 * @param end The end index of the displayed results.
 * @param searchTerm The search term.
 * @param start The start index of the displayed results.
 * @param totalResults The total number of results.
 * @returns {*}
 */
function PagingInfoView({ end, searchTerm, start, totalResults }) {
  return (
    <Typography variant={"body2"}>
      {strings.showing} {start} - {end} {strings.of} {totalResults}
    </Typography>
  );
}

PagingInfoView.propTypes = {
  end: PropTypes.number.isRequired,
  searchTerm: PropTypes.string.isRequired,
  start: PropTypes.number.isRequired,
  totalTesults: PropTypes.number
};

export default PagingInfoView;
