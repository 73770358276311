import React, { useCallback, useEffect, useReducer, useState } from "react";
import { strings } from "./localStrings";
import { Box, Snackbar } from "@material-ui/core";
import MainAppBar from "./components/MainAppBar";
import Footer from "./components/Footer";
import { BrowserRouter as Router } from "react-router-dom";
import SnackbarContentWrapper from "./components/SnackbarContentWrapper";
import { SimilarContext } from "./context/SimilarContext";
import { LoginContext } from "./context/LoginContext";
import { SimilarBasketsContext } from "./context/SimilarBasketsContext";
import { MobileDrawerContext } from "./context/MobileDrawerContext";
import { LanguageContext } from "./context/LanguageContext";
import { SessionContext } from "./context/SessionContext";
import { requestError } from "./utility/requestError";
import axios from "axios";
import equal from "deep-equal";
import { MainContent } from "./components/MainContent";
import { PageHeader } from "./components/PageHeader";
// import useStyles last, or the styles will be overwritten by the standard material-ui styles!
import useStyles from "./useStyles";
import documentRequest from "./requests/documentRequest";

/**
 * Main entry point for the application.
 *
 * @returns {*}
 */
function App() {
  const classes = useStyles();

  const languageReducer = (state, action) => {
    switch (action.type) {
      case "set":
        document.documentElement.lang = action.value;
        return action.value;
      default:
        throw new Error("Action " + action.type + " not available.");
    }
  };
  const [language, languageDispatch] = useReducer(
    languageReducer,
    localStorage.getItem("language") === null
      ? "de"
      : localStorage.getItem("language")
  );

  const [languageChanged, setLanguageChanged] = useState(false);

  useEffect(() => {
    localStorage.setItem("language", language);
    strings.setLanguage(language);
    document.documentElement.lang = language;
    setLanguageChanged(s => !s);
  }, [language]);

  const [languageContextState, setLanguageContextState] = useState({
    language: language,
    languageDispatch: languageDispatch,
    languageChanged: languageChanged
  });

  useEffect(() => {
    if (
      language !== languageContextState.language ||
      languageChanged !== languageContextState.languageChanged
    ) {
      setLanguageContextState({
        language: language,
        languageDispatch: languageDispatch,
        languageChanged: languageChanged
      });
    }
  }, [
    language,
    languageChanged,
    languageContextState.language,
    languageContextState.languageChanged
  ]);

  // State for LoginContext
  const loginStateInit = () => {
    let bearer = localStorage.getItem("Bearer");
    return bearer !== "" && bearer !== null;
  };

  const loginStateReducer = (state, action) => {
    switch (action.type) {
      case "login":
        return true;
      case "logout":
        return false;
      default:
        throw new Error("Action " + action.type + " not available.");
    }
  };
  const [loginState, loginStateDispatcher] = useReducer(
    loginStateReducer,
    false,
    loginStateInit
  );

  const [loginContextState, setLoginContextState] = useState({
    loginState: loginState,
    loginStateDispatcher: loginStateDispatcher
  });

  useEffect(() => {
    if (loginState !== loginContextState.loginState) {
      setLoginContextState({
        loginState: loginState,
        loginStateDispatcher: loginStateDispatcher
      });
    }
  }, [loginContextState.loginState, loginState]);

  const sessionExpiredReducer = (state, action) => {
    switch (action.type) {
      case "expired":
        return true;
      case "reset":
        return false;
      default:
        throw new Error("Action " + action.type + " not available.");
    }
  };
  const [sessionExpired, sessionExpiredDispatcher] = useReducer(
    sessionExpiredReducer,
    false
  );

  const loginSuccessReducer = (state, action) => {
    switch (action.type) {
      case "success":
        return true;
      case "reset":
        return false;
      default:
        throw new Error();
    }
  };
  const [loginSuccess, loginSuccessDispatcher] = useReducer(
    loginSuccessReducer,
    false
  );

  const notLoggedInReducer = (state, action) => {
    switch (action.type) {
      case "set":
        return true;
      case "reset":
        return false;
      default:
        throw new Error("Action " + action.type + " not available.");
    }
  };
  const [notLoggedIn, notLoggedInDispatcher] = useReducer(
    notLoggedInReducer,
    false
  );

  const notFoundReducer = (state, action) => {
    switch (action.type) {
      case "set":
        return true;
      case "reset":
        return false;
      default:
        throw new Error();
    }
  };
  const [notFound, notFoundDispatcher] = useReducer(notFoundReducer, false);

  const [sessionContextState, setSessionContextState] = useState({
    loginSuccess: loginSuccess,
    loginSuccessDispatcher: loginSuccessDispatcher,
    notLoggedIn: notLoggedIn,
    notLoggedInDispatcher: notLoggedInDispatcher,
    sessionExpiredDispatcher: sessionExpiredDispatcher,
    notFound: notFound,
    notFoundDispatcher: notFoundDispatcher
  });

  useEffect(() => {
    if (
      loginSuccess !== sessionContextState.loginSuccess ||
      notLoggedIn !== sessionContextState.notLoggedIn ||
      notFound !== sessionContextState.notFound
    ) {
      setSessionContextState({
        loginSuccess: loginSuccess,
        loginSuccessDispatcher: loginSuccessDispatcher,
        notLoggedIn: notLoggedIn,
        notLoggedInDispatcher: notLoggedInDispatcher,
        sessionExpiredDispatcher: sessionExpiredDispatcher,
        notFound: notFound,
        notFoundDispatcher: notFoundDispatcher
      });
    }
  }, [
    loginSuccess,
    notFound,
    notLoggedIn,
    sessionContextState.loginSuccess,
    sessionContextState.notFound,
    sessionContextState.notLoggedIn
  ]);

  const mobileDrawerReducer = (state, action) => {
    switch (action.type) {
      case "show":
        return true;
      case "hide":
        return false;
      default:
        throw new Error("Action " + action.type + " not available.");
    }
  };
  const showMobileDrawerButtonReducer = (state, action) => {
    switch (action.type) {
      case "show":
        return true;
      case "hide":
        return false;
      default:
        throw new Error("Action " + action.type + " not available.");
    }
  };
  const [mobileDrawerState, mobileDrawerDispatch] = useReducer(
    mobileDrawerReducer,
    false
  );
  const [showMobileDrawerButton, showMobileDrawerButtonDispatch] = useReducer(
    showMobileDrawerButtonReducer,
    true
  );

  const [mobileDrawerContextState, setMobileDrawerContextState] = useState({
    mobileDrawerState: mobileDrawerState,
    showMobileDrawerButton: showMobileDrawerButton,
    mobileDrawerDispatch: mobileDrawerDispatch,
    showMobileDrawerButtonDispatch: showMobileDrawerButtonDispatch
  });

  useEffect(() => {
    if (
      mobileDrawerState !== mobileDrawerContextState.mobileDrawerState ||
      showMobileDrawerButton !== mobileDrawerContextState.showMobileDrawerButton
    ) {
      setMobileDrawerContextState({
        mobileDrawerState: mobileDrawerState,
        showMobileDrawerButton: showMobileDrawerButton,
        mobileDrawerDispatch: mobileDrawerDispatch,
        showMobileDrawerButtonDispatch: showMobileDrawerButtonDispatch
      });
    }
  }, [
    mobileDrawerContextState.mobileDrawerState,
    mobileDrawerContextState.showMobileDrawerButton,
    mobileDrawerState,
    showMobileDrawerButton
  ]);

  // State for Similar Context
  const [similarDocuments, setSimilarDocuments] = useState([]);

  useEffect(() => {
    sessionStorage.setItem("searchlike", JSON.stringify(similarDocuments));
  }, [similarDocuments]);

  const removeSimilarDocument = useCallback(id => {
    setSimilarDocuments(state =>
      state.filter(function(item) {
        return item.id !== id;
      })
    );
  }, []);

  const addSimilarDocument = useCallback(
    (title, id, docIndex) => {
      let state = [...similarDocuments];
      const filtered = state.filter(function(item) {
        return item.id === id;
      });
      if (filtered.length !== 0) {
        return;
      }

      state.push({ label: title, id: id, index: docIndex });
      setSimilarDocuments(state);
    },
    [similarDocuments]
  );

  const resetSimilarDocuments = useCallback(() => {
    setSimilarDocuments([]);
  }, []);

  // State for SimilarBasketsContext
  const [similarBaskets, setSimilarBaskets] = useState([]);

  useEffect(() => {
    sessionStorage.setItem("basketlike", JSON.stringify(similarBaskets));
  }, [similarBaskets]);

  const addSimilarBasket = useCallback(
    (id, title) => {
      let state = [...similarBaskets];
      const filtered = state.filter(function(item) {
        return item.id === id;
      });
      if (filtered.length !== 0) {
        return;
      }
      state.push({ id: id, label: title });
      setSimilarBaskets(state);
    },
    [similarBaskets]
  );

  const removeSimilarBasket = useCallback(
    id => {
      let state = [...similarBaskets];
      state = state.filter(function(item) {
        return item.id !== id;
      });
      setSimilarBaskets(state);
    },
    [similarBaskets]
  );

  const resetSimilarBaskets = useCallback(() => {
    setSimilarBaskets([]);
  }, []);

  const [similarContextState, setSimilarContextState] = useState({
    similarDocuments: similarDocuments,
    addSimilarDocument: addSimilarDocument,
    removeSimilarDocument: removeSimilarDocument,
    resetSimilarDocuments: resetSimilarDocuments,
    setSimilarDocuments: setSimilarDocuments
  });

  useEffect(() => {
    if (!equal(similarDocuments, similarContextState.similarDocuments)) {
      setSimilarContextState({
        similarDocuments: similarDocuments,
        addSimilarDocument: addSimilarDocument,
        removeSimilarDocument: removeSimilarDocument,
        resetSimilarDocuments: resetSimilarDocuments,
        setSimilarDocuments: setSimilarDocuments
      });
    }
  }, [
    addSimilarDocument,
    removeSimilarDocument,
    resetSimilarDocuments,
    similarContextState.similarDocuments,
    similarDocuments
  ]);

  const [similarBasketContextState, setSimilarBasketContextState] = useState({
    similarBaskets: similarBaskets,
    setSimilarBaskets: setSimilarBaskets,
    addSimilarBasket: addSimilarBasket,
    removeSimilarBasket: removeSimilarBasket,
    resetSimilarBaskets: resetSimilarBaskets
  });

  useEffect(() => {
    if (!equal(similarBaskets, similarBasketContextState.similarBaskets)) {
      setSimilarBasketContextState({
        similarBaskets: similarBaskets,
        setSimilarBaskets: setSimilarBaskets,
        addSimilarBasket: addSimilarBasket,
        removeSimilarBasket: removeSimilarBasket,
        resetSimilarBaskets: resetSimilarBaskets
      });
    }
  }, [
    addSimilarBasket,
    removeSimilarBasket,
    resetSimilarBaskets,
    similarBasketContextState.similarBaskets,
    similarBaskets
  ]);

  /**
   * Closes the snackbar.
   *
   * @param event
   * @param reason
   */
  function handleClose(event, reason) {
    if (reason === "clickaway") {
      return;
    }
    sessionExpiredDispatcher({ type: "reset" });
    loginSuccessDispatcher({ type: "reset" });
    notLoggedInDispatcher({ type: "reset" });
    notFoundDispatcher({ type: "reset" });
  }

  const packageJson = require("../package.json");
  const url = new URL(packageJson.homepage);
  const basename = //Remove any leading '/'
    url.pathname.length > 0 && url.pathname.endsWith("/")
      ? url.pathname.substr(0, url.pathname.length - 1)
      : url.pathname;

  return (
    <Box className={[classes.root, classes.background].join(" ")}>
      <Router basename={basename}>
        <PageHeader basename={basename} />
        <LanguageContext.Provider value={languageContextState}>
          <MobileDrawerContext.Provider value={mobileDrawerContextState}>
            <LoginContext.Provider value={loginContextState}>
              <SessionContext.Provider value={sessionContextState}>
                <MainAppBar />
                <SimilarContext.Provider value={similarContextState}>
                  <SimilarBasketsContext.Provider
                    value={similarBasketContextState}
                  >
                    <MainContent />
                  </SimilarBasketsContext.Provider>
                </SimilarContext.Provider>

                <Footer />

                <Snackbar
                  anchorOrigin={{ vertical: "top", horizontal: "center" }}
                  open={sessionExpired}
                  autoHideDuration={6000}
                  onClose={handleClose}
                >
                  <SnackbarContentWrapper
                    onClose={handleClose}
                    variant={"warning"}
                    message={strings.loginExpired}
                  />
                </Snackbar>
                <Snackbar
                  anchorOrigin={{ vertical: "top", horizontal: "center" }}
                  open={notLoggedIn}
                  autoHideDuration={6000}
                  onClose={handleClose}
                >
                  <SnackbarContentWrapper
                    onClose={handleClose}
                    variant={"warning"}
                    message={strings.notLoggedIn}
                  />
                </Snackbar>
                <Snackbar
                  anchorOrigin={{ vertical: "top", horizontal: "center" }}
                  open={loginSuccess}
                  autoHideDuration={6000}
                  onClose={handleClose}
                >
                  <SnackbarContentWrapper
                    onClose={handleClose}
                    variant={"success"}
                    message={strings.logInSuccess}
                  />
                </Snackbar>
                <Snackbar
                  anchorOrigin={{ vertical: "top", horizontal: "center" }}
                  open={notFound}
                  autoHideDuration={6000}
                  onClose={handleClose}
                >
                  <SnackbarContentWrapper
                    onClose={handleClose}
                    variant={"warning"}
                    message={strings.notFound}
                  />
                </Snackbar>
              </SessionContext.Provider>
            </LoginContext.Provider>
          </MobileDrawerContext.Provider>
        </LanguageContext.Provider>
      </Router>
    </Box>
  );
}
App.whyDidYouRender = true;

export default App;
